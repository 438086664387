
import {
    DxGroupItem,
    DxSimpleItem,
    DxLabel,
    DxItem,
    DxForm,

} from 'devextreme-vue/form';


import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxLookup,
    DxFormItem,
    DxValidationRule,
    DxButton

} from 'devextreme-vue/data-grid';



import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxDateBox from 'devextreme-vue/date-box';
import DxTextArea from 'devextreme-vue/text-area';



import { DxSelectBox } from 'devextreme-vue/select-box';
import axios from 'axios';
import DxTabPanel from "devextreme-vue/tab-panel";
import { customers } from './../data/dataperfil.js';
import 'devextreme-vue/text-area';
import notify from 'devextreme/ui/notify';
import Cookies from "js-cookie";
import auth from "../auth";
window.Cookies = Cookies;
import moment from 'moment';
import ProcedimientoAdministrativo from '../services/procedimiento-service';
import OrdenTrabajoResultadoService from '../services/ordentrabajoresultado-service';
import OrdenTrabajoDocumentoService from '../services/ordentrabajodocumento-service';
import OrdenTrabajoProcedimientoMedidasService from '../services/ordentrabajoprocedimientomedidas-service';
import OrdenTrabajoProcedimientoTerminosService from '../services/ordentrabajoprocedimientoterminos-service';
import OrdenTrabajoProcedimientoCondicionalesService from '../services/ordentrabajoprocedimientocondicionales-service';
import OrdenTrabajoProcedimientoProgramasService from '../services/ordentrabajoprocedimientoprogramas-service';
import OrdenTrabajoEtapaService from '../services/ordentrabajoetapa-service';
import TipoDocumentoService from '../services/tipodocumento-service';
import EstatusService from '../services/estatus-service';
import OrdenTrabajoPlanService from '../services/ordentrabajoplan-service';
import Servicioervice from '../services/servicio-service';
import ResponsableTecnicoService from '../services/responsabletecnico-service';
import TipoProyectoService from '../services/tipoproyecto-service';

import ClienteContactoService from '../services/clientecontacto-service';
import VisitaCampoService from '../services/reportevisitacampo-service';

import 'jspdf-autotable';


import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxTextBox } from 'devextreme-vue/text-box';

const sendRequest = function (value) {
    const validEmail = 'test@dx-email.com';
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(value === validEmail);
        }, 1000);
    });
};

export default {
    components: {
        DxGroupItem,
        DxSimpleItem,
        DxLookup,
        //DxEmailRule,
        DxDataGrid,
        DxTabPanel,
        DxItem,
        DxEditing,
        DxColumn,
        DxPaging,
        DxForm,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxLabel,
        DxFormItem,
        DxSelectBox,


        DxFileUploader,
        DxTextBox,
        DxValidationRule,

        DxButton,

        DxPopup,
        DxScrollView,
        DxToolbarItem,
        DxDateBox,
        DxTextArea,


    },
    data() {
        return {

            /** NUEVO PROCEDIMIENTO ADMINISTRATIVO */

            // Variables de visibilidad

            vis_medio_impugnaciondetalleCMA: 'N',
            vis_medio_impugnaciondetalleCMB: 'N',
            vis_medio_impugnaciondetalleCMC: 'N',
            vis_medio_impugnaciondetalleCMD: 'N',
            vis_medio_impugnaciondetalleCME: 'N',
            vis_medio_impugnaciondetalleCME_E: 'N',
            vis_medio_impugnaciondetalleCMF: 'N',

            vis_medio_impugnaciondetalleCMA_MED: 'N',
            vis_medio_impugnaciondetalleCMB_MED: 'N',
            vis_medio_impugnaciondetalleCMC_MED: 'N',
            vis_medio_impugnaciondetalleCMD_MED: 'N',
            vis_medio_impugnaciondetalleCME_MED: 'N',
            vis_medio_impugnaciondetalleCMF_MED: 'N',


            vis_medio_impugnaciondetalleRMA_MED: 'N',
            vis_medio_impugnaciondetalleRMB_MED: 'N',
            vis_medio_impugnaciondetalleRMC_MED: 'N',
            vis_medio_impugnaciondetalleRMD_MED: 'N',
            vis_medio_impugnaciondetalleRME_MED: 'N',
            vis_medio_impugnaciondetalleRMF_MED: 'N',

            vis_medio_impugnaciondetalleJCA_MED: 'N',
            vis_medio_impugnaciondetalleJCB_MED: 'N',
            vis_medio_impugnaciondetalleJCC_MED: 'N',
            vis_medio_impugnaciondetalleJCD_MED: 'N',
            vis_medio_impugnaciondetalleJNA_MED: 'N',
            vis_medio_impugnaciondetalleJNB_MED: 'N',
            vis_medio_impugnaciondetalleJNC_MED: 'N',
            vis_medio_impugnaciondetalleJND_MED: 'N',
            vis_medio_impugnaciondetalleJNE_MED: 'N',

            vis_medio_impugnaciondetalleRRA_MED: 'N',
            vis_medio_impugnaciondetalleRRB_MED: 'N',
            vis_medio_impugnaciondetalleRRC_MED: 'N',
            vis_medio_impugnaciondetalleRRD_MED: 'N',
                    

            conmutacion_e: 0,

            dataSourceMedioImpugnacionCMA: null,
            dataSourceMedioImpugnacionCMB: null,
            dataSourceMedioImpugnacionCMC: null,
            dataSourceMedioImpugnacionCMD: null,
            dataSourceMedioImpugnacionCME: null,
            dataSourceMedioImpugnacionCME_E: null,
            dataSourceMedioImpugnacionCMF: null,

            vis_acta_materia: 'N',
            v_acta: '',
            v_medio: '',
            v_mediodetalle: '',
            vis_actainspeccion: 'N',
            vis_contestacion: 'N',
            vis_acuerdo: 'N',
            vis_otros: 'N',
            vis_alegatos: 'N',
            vis_resolucion: 'N',
            vis_medioimp: 'N',

            vis_medidaseguridad_acta: 'N',
            vis_medidaurgencia_acta: 'N',
            vis_levantamientosello_acta: 'N',
            vis_acuerdoemp_medidacorrectiva: 'N',
            vis_acuerdoemp_medidaseguridad: 'N',
            vis_acuerdoemp_levantamiento: 'N',

            vis_otrosprroroga: 'N',
            vis_otrosmedidas: 'N',
            vis_otroscopias: 'N',
            vis_acuerdoemp_fechacontestacion: 'N',
            vis_medio_impugnaciondetalle: 'N',

            vis_medio_impugnaciondetalleJCA: 'N',
            dataSourceMedioImpugnacionJCA: null,
            vis_medio_impugnaciondetalleJCB: 'N',
            dataSourceMedioImpugnacionJCB: null,
            vis_medio_impugnaciondetalleJCC: 'N',
            dataSourceMedioImpugnacionJCC: null,
            vis_medio_impugnaciondetalleJCD: 'N',
            dataSourceMedioImpugnacionJCD: null,


            vis_medio_impugnaciondetalleJNA: 'N',
            dataSourceMedioImpugnacionJNA: null,

            vis_medio_impugnaciondetalleJNB: 'N',
            dataSourceMedioImpugnacionJNB: null,
            vis_medio_impugnaciondetalleJNC: 'N',
            dataSourceMedioImpugnacionJNC: null,
            vis_medio_impugnaciondetalleJND: 'N',
            dataSourceMedioImpugnacionJND: null,
            vis_medio_impugnaciondetalleJNE: 'N',
            dataSourceMedioImpugnacionJNE: null,


            vis_medio_impugnaciondetalleRRA: 'N',
            dataSourceMedioImpugnacionRRA: null,
            vis_medio_impugnaciondetalleRRB: 'N',
            dataSourceMedioImpugnacionRRB: null,
            vis_medio_impugnaciondetalleRRC: 'N',
            dataSourceMedioImpugnacionRRC: null,
            vis_medio_impugnaciondetalleRRE: 'N',
            dataSourceMedioImpugnacionRRE: null,
            vis_medio_impugnaciondetalleRRD: 'N',
            dataSourceMedioImpugnacionRRD: null,



            vis_medio_impugnaciondetalleRMA: 'N',
            dataSourceMedioImpugnacionRMA: null,
            vis_medio_impugnaciondetalleRMB: 'N',
            dataSourceMedioImpugnacionRMB: null,
            vis_medio_impugnaciondetalleRMC: 'N',
            dataSourceMedioImpugnacionRMC: null,
            vis_medio_impugnaciondetalleRMD: 'N',
            dataSourceMedioImpugnacionRMD: null,
            vis_medio_impugnaciondetalleRME: 'N',
            dataSourceMedioImpugnacionRME: null,
            vis_medio_impugnaciondetalleRMF: 'N',
            dataSourceMedioImpugnacionRMF: null,


            vis_medidacorrectiva_resolucion: 'N',
            vis_medidaseguridad_resolucion: 'N',
            vis_levantamiento_resolucion: 'N',
            dataSourceMedidaSeguridadResolucion: null,
            dataSourceMedidaCorrectivaResolucion: null,
            dataSourceLevantamientoSelloResolucion: null,
            dataSourceMedioImpugnacionDetall: null,
            dataSourceMedioImpugnacionDetalleConsulta: null,
            dataSourceAcuerdoEmpFecha: null,
            dataSourceActaMateria: null,
            dataSourceOtrosMedidas: null,
            dataSourceOtrosCopias: null,
            dataSourceAcuerdoEmplazamientoLevantamientoSello: null,
            dataSourceAcuerdoEmplazamientoMedidaSeguridad: null,
            dataSourceActaInspeccion: null,
            dataSourceContestacionActaInspeccion: null,
            dataSourceAcuerdoEmplazamiento: null,
            dataSourceCompetencia: null,
            dataSourceMateria: null,
            dataSourceTipoRespuesta: null,
            dataSourceOtros: null,
            dataSourceOtrosProrroga: null,
            dataSourceAlegatos: null,
            dataSourceResolucion: null,
            dataSourceMedioImpugnacion: null,
            dataSourceMedioImpugnacionConsulta: null,
            DataSourceOT: null,
            dataSourceServicio: null,
            dataSourceMedidaSeguridadActa: null,
            dataSourceMedidaUrgenciaActa: null,
            dataSourceLevantamientoSelloActa: null,
            dataSourceClasificacion: null,
            dataSourceVisibilidad: null,

            dataSourceDiaMedida: null,
            vMedioImpugnacionCatalogoId: 0,
            vMedioImpugnacionId: 0,
            vActaInspeccionId: 0,
            vResolucionId: 0,
            vActaAcuerdoEmplazamientoId: 0,
            vOtrosId: 0,


            /** SERVICIOS */
            dataSourceClienteContacto: null,
            dataSourceClienteContactoEditar: null,
            frm_clientecontacto: null,
            frm_ed_clientecontacto: null,

            dataSourceVisitaCampo: null,
            dataSourceTipoProyecto: null,
            dataSourceResponsable: null,
            frm_responsable: null,
            frm_servicio: null,
            frm_numero: null,
            frm_fechainiciovigencia: null,
            frm_fechafinvigencia: null,
            frm_seguimiento: null,
            frm_fechainicioplaneada: null,
            frm_fechafinplaneada: null,
            frm_observaciones: null,
            frm_aplicaeca: null,
            frm_tipoproyectoid: null,
            frm_ecaid: null,
            dataSourceECA: null,
            v_es_eca: 'N',
            frm_visitacampoid: null,
            popupVisibleServicios: false,
            popupTitleServicios: "Orden Trabajo",
            vmedioimpugnaciondetalleid: null,

            popupVisibleServiciosEdicion: false,
            popupTitleServiciosEdicion: "Edición de Orden Trabajo",
            v_ed_aplicacambioservicio: 'S',
            frm_ed_id: null,
            frm_ed_servicio: null,
            frm_ed_tiposervicioid: null,
            frm_ed_responsable: null,
            frm_ed_tipoproyectoid: null,
            frm_ed_visitacampoid: null,
            frm_ed_fechainicioplaneada: null,
            frm_ed_fechafinplaneada: null,
            frm_ed_observaciones: null,
            frm_ed_solicitud: null,
            frm_ed_cotizacion: null,
            frm_ed_ot: null,
            frm_ed_cliente: null,
            popupVisible: false,
            popupTitle: "Servicios Poliza",

            vclienteid: null,

            dataSourceCierre: null,



            popupOptionsServicios: {
                title: 'Servicios en Poliza',
                showTitle: true,
                width: 900,
                height: 600,
            },

            popupOptionsCambioEstatus: {
                title: 'Cambio Estatus',
                showTitle: true,
                width: 500,
                height: 310,
            },

            /***************************** */
            loading: true,
            tasks: null,
            taskTitlePosition: 'outside',
            dependencies: null,
            resources: null,
            resourceAssignments: null,


            dataSourceEstatus: null,
            dataSourceEstatusCierreOT: null,
            dataSourceCambioEstatus: null,
            dataSource: customers,
            dataSourceEtapaCierre: null,
            dataSourceEntrega: null,
            dataSourceListados: null,
            dataSourceDocumentacion: null,
            dataSourceEtapa: null,
            dataSourceHistorial: null,
            dataSourceDocumento: null,
            dataSourceAccion: null,
            dataSourceTipoMedida: null,
            dataSourceFactorAmbiental: null,
            dataSourceMedidasMitigacion: null,
            dataSourceMedidasSeguimiento: null,
            dataIdAccionSeleccionado: null,
            dataSourceTermino: null,
            dataSourceCondicion: null,
            dataSourcePrograma: null,
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptionsListado: {
                title: 'Resultados',
                showTitle: true,
                width: 650,
                height: 400,
            },
            popupOptions: {
                title: 'Resultados',
                showTitle: true,
                width: 650,
                height: 500,
            }, popupOptions2: {
                title: 'Resultados',
                showTitle: true,
                width: 500,
                height: 500,
            },
            popupOptions3: {
                title: 'Cambio Etapa',
                showTitle: true,
                width: 400,
                height: 200,
            },
            popupOptionsEstatus: {
                title: 'Estatus',
                showTitle: true,
                width: 400,
                height: 200,
            },
            valetapa: 0,
            valcierre: '',
            valtipodocumento: 0,
            valnombredocumento: '',
            columns: ['CompanyName', 'City', 'State', 'Phone', 'Fax'],
            customer: null,//service.getCustomer(),
            buttonOptions: {
                text: 'Actualizar Orden Trabajo',
                type: 'success',
                useSubmitBehavior: true,
            },
            readonlyOptions: {
                disabled: true,
            },
            passwordOptions: {
                mode: 'password',
            },
            dateBoxOptions: {
                invalidDateMessage:
                    'Campos tipo fecha debe tener el siguiente formato: MM/dd/yyyy',
            },
            checkBoxOptions: {
                text: 'I agree to the Terms and Conditions',
                value: false,
            },
            phoneEditorOptions: {
                mask: '+1 (X00) 000-0000',
                maskRules: {
                    X: /[02-9]/,
                },
                maskInvalidMessage: 'The phone must have a correct USA phone format',
            },
            currencyOptions: {
                mask: '#,##0.00',
                maskInvalidMessage: 'The phone must have a correct USA phone format',

            },
            esCampoRequerido: true,
            esCampoRequerido2: true,
            opcionesItem: {
                width: '200%',
            },

            maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
            namePattern: /^[^0-9]+$/,
            cityPattern: /^[^0-9]+$/,
            phonePattern: /^[02-9]\d{9}$/,
            numsPattern: /[0-9]/,
            vidot: null,
            backendURL: process.env.VUE_APP_APIURL,
            exportButtonOptions: {
                hint: 'Export to PDF',
                icon: 'exportpdf',
                stylingMode: 'text',
                onClick: () => {
                    this.exportGantt();
                },
            },
        };
    },
    computed: {

    },
    created() {


        this.ProcedimientoAdministrativo = new ProcedimientoAdministrativo();
        this.visitacampoService = new VisitaCampoService();
        this.ClienteContactoService = new ClienteContactoService();
        this.responsabletecnicoService = new ResponsableTecnicoService();
        this.tipoproyectoService = new TipoProyectoService();
        this.ordentrabajoresultadoService = new OrdenTrabajoResultadoService();
        this.ordentrabajoetapaService = new OrdenTrabajoEtapaService();
        this.ordentrabajodocumentoService = new OrdenTrabajoDocumentoService();
        this.ordentrabajoprocedimientomedidasService = new OrdenTrabajoProcedimientoMedidasService();
        this.ordentrabajoprocedimientoterminosService = new OrdenTrabajoProcedimientoTerminosService();
        this.ordentrabajoprocedimientocondicionalesService = new OrdenTrabajoProcedimientoCondicionalesService();
        this.ordentrabajoprocedimientoprogramasService = new OrdenTrabajoProcedimientoProgramasService();
        this.ordentrabajoplanService = new OrdenTrabajoPlanService();
        this.estatusService = new EstatusService();
        this.tipodocumentoService = new TipoDocumentoService();
        this.servicioService = new Servicioervice();
        this.vidot = this.$route.params.id;


    },
    mounted() {
        this.loadDataInfo();
        // Nuevo Procedimiento Administrativo
        this.cargaCatalogos();
        this.cargaDatos();
        this.controlaVisibilidad();
    },
    methods: {

        onRowPrepared(e) {
            if (e.rowType === "data") {
                if (e.data.descripcion !== "") {

                    e.rowElement.classList.add("wrap");

                }
            }
        },


        cargaDatosServicios(myJSON) {



            this.ProcedimientoAdministrativo.getOrdenTrabajoServicioProcedimiento(myJSON).then(data => {
                this.dataSourceOT = data;

            }
            );

            this.responsabletecnicoService.getResponsableTecnicoOrden().then(data => this.dataSourceResponsable = data);
            this.tipoproyectoService.getTipoProyecto().then(data => this.dataSourceTipoProyecto = data);
            this.servicioService.getServicio().then(data => this.dataSourceServicio = data);
            this.estatusService.getGrupoEstatusOrdenPoliza(myJSON).then(data => this.dataSourceEstatus = data);
            this.estatusService.getGrupoEstatusOrden(myJSON).then(data => this.dataSourceEstatusCierreOT = data);




            this.ProcedimientoAdministrativo.getOrdenTrabajoServicioProcedimientoPorId(myJSON).then(data => {
                this.customer = data;

                let myJSON_vs = JSON.stringify({
                    pclienteid: data.clienteid,
                });
                this.visitacampoService.getDetalleReporteVisitaPorCliente(myJSON_vs).then(data => this.dataSourceVisitaCampo = data);

                let myJSON = JSON.stringify({
                    psolicitudid: data.solicitudid,
                });
                this.ClienteContactoService.getClienteContactoCotizacion(myJSON).then(data => {
                    this.dataSourceClienteContactoEditar = data;
                    this.dataSourceClienteContacto = data;

                });


            }
            );





            /**
            this.ordentrabajoService.getPolizaDetallePorId(myJSON).then(data => {
                  this.dataSourceCierre = data
                }
            );  
              */


        },
        cargaDatos() {
            let myJSON = JSON.stringify({
                pid: this.vidot,
            });

           
           this.consultaActaInspeccion(myJSON);
           this.consultaMedidasSeguridadActaInspeccion();
           this.consultaMedidasUrgenciaActaInspeccion();
           this.consultaLevantamientoSelloActaInspeccion();
           this.consultaActaInspeccionMateria();

            
           this.consultaContestacionActaInspeccion(myJSON);

           this.consultaAcuerdoEmplazamiento(myJSON);
           this.consultaAcuerdoEmplazamientoFechaContestacion();
           this.consultaMedidaCorrectivaAcuerdoEmplazamiento();

           this.consultaOtros(myJSON);
           this.consultaOtrosProrroga();
           this.consultaOtrosMedidas();
           this.consultaOtrosCopias();

           this.consultaAlegatos(myJSON);
           this.consultaResolucion(myJSON);
           
          
            // MEDIOS IMPUGNACION
            this.consultaMedioImpugnacion(myJSON);
            this.consultaMedioImpugnacionDetalle();
            this.consultaMedioImpugnacionDetalleConsulta();

            this.consultaMedioImpugnacionjca();
            this.consultaMedioImpugnacionjcb();
            this.consultaMedioImpugnacionjcc();
            this.consultaMedioImpugnacionjcd();

            this.consultaMedioImpugnacionjna();
            this.consultaMedioImpugnacionjnb();
            this.consultaMedioImpugnacionjnc();
            this.consultaMedioImpugnacionjnd();
            this.consultaMedioImpugnacionjne();


            this.consultaMedioImpugnacionrra();
            this.consultaMedioImpugnacionrrb();
            this.consultaMedioImpugnacionrrc();
            this.consultaMedioImpugnacionrrd();



            this.consultaMedioImpugnacionrma();
            this.consultaMedioImpugnacionrmb();
            this.consultaMedioImpugnacionrmc();
            this.consultaMedioImpugnacionrmd();
            this.consultaMedioImpugnacionrme();
            this.consultaMedioImpugnacionrmf();

            this.consultaMedioImpugnacioncma();
            this.consultaMedioImpugnacioncmb();
            this.consultaMedioImpugnacioncmc();
            this.consultaMedioImpugnacioncmd();
            this.consultaMedioImpugnacioncme();
            this.consultaMedioImpugnacioncme_e();
            this.consultaMedioImpugnacioncmf();
 

            this.cargaDatosServicios(myJSON);
        },
        controlaVisibilidadMedios(val) {
           
            if (val == '22') {
                this.vis_medio_impugnaciondetalleJCA == 'S'?this.vis_medio_impugnaciondetalleJCA = 'N':this.vis_medio_impugnaciondetalleJCA = 'S';
            }
            if (val == '23') {
               
                this.vis_medio_impugnaciondetalleJCB == 'S'?this.vis_medio_impugnaciondetalleJCB = 'N':this.vis_medio_impugnaciondetalleJCB = 'S';
            }
            if (val == '24') {
             
                this.vis_medio_impugnaciondetalleJCC == 'S'?this.vis_medio_impugnaciondetalleJCC = 'N':this.vis_medio_impugnaciondetalleJCC = 'S';
            }
            if (val == '25') {
                
                this.vis_medio_impugnaciondetalleJCD == 'S'?this.vis_medio_impugnaciondetalleJCD = 'N':this.vis_medio_impugnaciondetalleJCD = 'S';
            }



            if (val == '17') {
              
                this.vis_medio_impugnaciondetalleJNA == 'S'?this.vis_medio_impugnaciondetalleJNA = 'N':this.vis_medio_impugnaciondetalleJNA = 'S';
            }
            if (val == '18') {
             
                this.vis_medio_impugnaciondetalleJNB == 'S'?this.vis_medio_impugnaciondetalleJNB = 'N':this.vis_medio_impugnaciondetalleJNB = 'S';
            }
            if (val == '19') {
           
                this.vis_medio_impugnaciondetalleJNC == 'S'?this.vis_medio_impugnaciondetalleJNC = 'N':this.vis_medio_impugnaciondetalleJNC = 'S';
            }
            if (val == '20') {
                
                this.vis_medio_impugnaciondetalleJND == 'S'?this.vis_medio_impugnaciondetalleJND = 'N':this.vis_medio_impugnaciondetalleJND = 'S';
            }
            if (val == '21') {
             
                this.vis_medio_impugnaciondetalleJNE == 'S'?this.vis_medio_impugnaciondetalleJNE = 'N':this.vis_medio_impugnaciondetalleJNE = 'S';
            }


            if (val == '13') {
                
                this.vis_medio_impugnaciondetalleRRA == 'S'?this.vis_medio_impugnaciondetalleRRA = 'N':this.vis_medio_impugnaciondetalleRRA = 'S';
            }
            if (val == '14') {
                
                this.vis_medio_impugnaciondetalleRRB == 'S'?this.vis_medio_impugnaciondetalleRRB = 'N':this.vis_medio_impugnaciondetalleRRB = 'S';
            }
            if (val== '15') {
                 
                this.vis_medio_impugnaciondetalleRRC == 'S'?this.vis_medio_impugnaciondetalleRRC = 'N':this.vis_medio_impugnaciondetalleRRC = 'S';
            }
            if (val== '16') {
                
                this.vis_medio_impugnaciondetalleRRD == 'S'?this.vis_medio_impugnaciondetalleRRD = 'N':this.vis_medio_impugnaciondetalleRRD = 'S';
            }

            if (val== '7') {
                
                this.vis_medio_impugnaciondetalleRMA == 'S'?this.vis_medio_impugnaciondetalleRMA = 'N':this.vis_medio_impugnaciondetalleRMA = 'S';
            }
            if (val== '8') {
                
                this.vis_medio_impugnaciondetalleRMB == 'S'?this.vis_medio_impugnaciondetalleRMB = 'N':this.vis_medio_impugnaciondetalleRMB = 'S';
            }
            if (val == '9') {
               
                this.vis_medio_impugnaciondetalleRMC == 'S'?this.vis_medio_impugnaciondetalleRMC = 'N':this.vis_medio_impugnaciondetalleRMC = 'S';
            }
            if (val == '10') {
               
                this.vis_medio_impugnaciondetalleRMD == 'S'?this.vis_medio_impugnaciondetalleRMD = 'N':this.vis_medio_impugnaciondetalleRMD = 'S';
            }
            if (val == '11') {
                
                this.vis_medio_impugnaciondetalleRME == 'S'?this.vis_medio_impugnaciondetalleRME = 'N':this.vis_medio_impugnaciondetalleRME = 'S';
            }
            if (val == '12') {
                
                this.vis_medio_impugnaciondetalleRMF == 'S'?this.vis_medio_impugnaciondetalleRMF = 'N':this.vis_medio_impugnaciondetalleRMF = 'S';
            }


            if (val== '1') {
                 
                this.vis_medio_impugnaciondetalleCMA == 'S'?this.vis_medio_impugnaciondetalleCMA = 'N':this.vis_medio_impugnaciondetalleCMA = 'S';
            }
            if (val== '2') {
               
                this.vis_medio_impugnaciondetalleCMB == 'S'?this.vis_medio_impugnaciondetalleCMB = 'N':this.vis_medio_impugnaciondetalleCMB = 'S';
            }
            if (val == '3') {
               
                this.vis_medio_impugnaciondetalleCMC == 'S'?this.vis_medio_impugnaciondetalleCMC = 'N':this.vis_medio_impugnaciondetalleCMC = 'S';
            }
            if (val== '4') {
            
                this.vis_medio_impugnaciondetalleCMD == 'S'?this.vis_medio_impugnaciondetalleCMD = 'N':this.vis_medio_impugnaciondetalleCMD = 'S';
            }
            if (val == '5') {
     
                this.vis_medio_impugnaciondetalleCME == 'S'?this.vis_medio_impugnaciondetalleCME = 'N':this.vis_medio_impugnaciondetalleCME = 'S';
            }
            if (val == '6') {
                
                this.vis_medio_impugnaciondetalleCMF == 'S'?this.vis_medio_impugnaciondetalleCMF = 'N':this.vis_medio_impugnaciondetalleCMF == 'S';
            }
         
            

        },
        regresar() {
          this.$router.push({ name: 'ordentrabajoresultado' });
         
        },
        controlaVisibilidad() {
            let myJSON = JSON.stringify({
                pid: this.vidot,
            });
            this.ProcedimientoAdministrativo.getControlaVisibilidad(myJSON).then(data => {
                this.dataSourceVisibilidad = data;


                if (data.medio_impugnaciondetalleCMA == '1') {
                    this.vis_medio_impugnaciondetalleCMA = 'S';
                    this.vis_medio_impugnaciondetalleCMA_MED='S';
                }
                else {
                    this.vis_medio_impugnaciondetalleCMA = 'N';
                    this.vis_medio_impugnaciondetalleCMA_MED='N';
                }

                if (data.medio_impugnaciondetalleCMB == '1') {
                    this.vis_medio_impugnaciondetalleCMB = 'S';
                    this.vis_medio_impugnaciondetalleCMB_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleCMB = 'N';
                    this.vis_medio_impugnaciondetalleCMB_MED = 'N';
                }
                if (data.medio_impugnaciondetalleCMC == '1') {
                    this.vis_medio_impugnaciondetalleCMC = 'S';
                    this.vis_medio_impugnaciondetalleCMC_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleCMC = 'N';
                    this.vis_medio_impugnaciondetalleCMC_MED = 'N';
                }
                if (data.medio_impugnaciondetalleCMD == '1') {
                    this.vis_medio_impugnaciondetalleCMD = 'S';
                    this.vis_medio_impugnaciondetalleCMD_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleCMD = 'N';
                    this.vis_medio_impugnaciondetalleCMD_MED = 'N';
                }
                if (data.medio_impugnaciondetalleCME == '1') {
                    this.vis_medio_impugnaciondetalleCME = 'S';
                    this.vis_medio_impugnaciondetalleCME_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleCME = 'N';
                    this.vis_medio_impugnaciondetalleCME_MED = 'N';
                }

                if (data.medio_impugnaciondetalleCME_E == '1') {
                    this.vis_medio_impugnaciondetalleCME_E = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleCME_E = 'N';
                }


                if (data.medio_impugnaciondetalleCMF == '1') {
                    this.vis_medio_impugnaciondetalleCMF = 'S';
                    this.vis_medio_impugnaciondetalleCMF_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleCMF = 'N';
                    this.vis_medio_impugnaciondetalleCMF_MED = 'N';
                }

                //RMA
                if (data.medio_impugnaciondetalleRMA == '1') {
                    this.vis_medio_impugnaciondetalleRMA = 'S';
                    this.vis_medio_impugnaciondetalleRMA_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleRMA = 'N';
                    this.vis_medio_impugnaciondetalleRMA_MED = 'N';
                }

                if (data.medio_impugnaciondetalleRMB == '1') {
                    this.vis_medio_impugnaciondetalleRMB = 'S';
                    this.vis_medio_impugnaciondetalleRMB_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleRMB = 'N';
                    this.vis_medio_impugnaciondetalleRMB_MED = 'N';
                }
                if (data.medio_impugnaciondetalleRMC == '1') {
                    this.vis_medio_impugnaciondetalleRMC = 'S';
                    this.vis_medio_impugnaciondetalleRMC_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleRMC = 'N';
                    this.vis_medio_impugnaciondetalleRMC_MED = 'N';
                }
                if (data.medio_impugnaciondetalleRMD == '1') {
                    this.vis_medio_impugnaciondetalleRMD = 'S';
                    this.vis_medio_impugnaciondetalleRMD_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleRMD = 'N';
                    this.vis_medio_impugnaciondetalleRMD_MED = 'N';
                }
                if (data.medio_impugnaciondetalleRME == '1') {
                    this.vis_medio_impugnaciondetalleRME = 'S';
                    this.vis_medio_impugnaciondetalleRME_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleRME = 'N';
                    this.vis_medio_impugnaciondetalleRME_MED = 'N';
                }
                if (data.medio_impugnaciondetalleRMF == '1') {
                    this.vis_medio_impugnaciondetalleRMF = 'S';
                    this.vis_medio_impugnaciondetalleRMF_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleRMF = 'N';
                    this.vis_medio_impugnaciondetalleRMF_MED = 'N';
                }


                //RRA
                if (data.medio_impugnaciondetalleRRA == '1') {
                    this.vis_medio_impugnaciondetalleRRA = 'S';
                    this.vis_medio_impugnaciondetalleRRA_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleRRA = 'N';
                    this.vis_medio_impugnaciondetalleRRA_MED = 'N';
                }
                if (data.medio_impugnaciondetalleRRB == '1') {
                    this.vis_medio_impugnaciondetalleRRB = 'S';
                    this.vis_medio_impugnaciondetalleRRB_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleRRB = 'N';
                    this.vis_medio_impugnaciondetalleRRB_MED = 'N';
                }
                if (data.medio_impugnaciondetalleRRC == '1') {
                    this.vis_medio_impugnaciondetalleRRC = 'S';
                    this.vis_medio_impugnaciondetalleRRC_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleRRC = 'N';
                    this.vis_medio_impugnaciondetalleRRC_MED = 'N';
                }
                if (data.medio_impugnaciondetalleRRD == '1') {
                    this.vis_medio_impugnaciondetalleRRD = 'S';
                    this.vis_medio_impugnaciondetalleRRD_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleRRD = 'N';
                    this.vis_medio_impugnaciondetalleRRD_MED = 'N';
                }

                // JNA
                if (data.medio_impugnaciondetalleJNA == '1') {
                    this.vis_medio_impugnaciondetalleJNA = 'S';
                    this.vis_medio_impugnaciondetalleJNA_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleJNA = 'N';
                    this.vis_medio_impugnaciondetalleJNA_MED = 'N';
                }

                if (data.medio_impugnaciondetalleJNB == '1') {
                    this.vis_medio_impugnaciondetalleJNB = 'S';
                    this.vis_medio_impugnaciondetalleJNB_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleJNB = 'N';
                    this.vis_medio_impugnaciondetalleJNB_MED = 'N';
                }
                if (data.medio_impugnaciondetalleJNC == '1') {
                    this.vis_medio_impugnaciondetalleJNC = 'S';
                    this.vis_medio_impugnaciondetalleJNC_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleJNC = 'N';
                    this.vis_medio_impugnaciondetalleJNC_MED = 'N';
                }
                if (data.medio_impugnaciondetalleJND == '1') {
                    this.vis_medio_impugnaciondetalleJND = 'S';
                    this.vis_medio_impugnaciondetalleJND_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleJND = 'N';
                    this.vis_medio_impugnaciondetalleJND_MED = 'N';
                }
                if (data.medio_impugnaciondetalleJNE == '1') {
                    this.vis_medio_impugnaciondetalleJNE = 'S';
                    this.vis_medio_impugnaciondetalleJNE_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleJNE = 'N';
                    this.vis_medio_impugnaciondetalleJNE_MED = 'N';
                }

                //JCA
                if (data.medio_impugnaciondetalleJCA == '1') {
                    this.vis_medio_impugnaciondetalleJCA = 'S';
                    this.vis_medio_impugnaciondetalleJCA_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleJCA = 'N';
                    this.vis_medio_impugnaciondetalleJCA_MED = 'N';
                }

                if (data.medio_impugnaciondetalleJCB == '1') {
                    this.vis_medio_impugnaciondetalleJCB = 'S';
                    this.vis_medio_impugnaciondetalleJCB_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleJCB = 'N';
                    this.vis_medio_impugnaciondetalleJCB_MED = 'N';
                }
                if (data.medio_impugnaciondetalleJCC == '1') {
                    this.vis_medio_impugnaciondetalleJCC = 'S';
                    this.vis_medio_impugnaciondetalleJCC_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleJCC = 'N';
                    this.vis_medio_impugnaciondetalleJCC_MED = 'N';
                }
                if (data.medio_impugnaciondetalleJCD == '1') {
                    this.vis_medio_impugnaciondetalleJCD = 'S';
                    this.vis_medio_impugnaciondetalleJCD_MED = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalleJCD = 'N';
                    this.vis_medio_impugnaciondetalleJCD_MED = 'N';
                }





                if (data.medio_impugnaciondetalle == '1') {
                    this.vis_medio_impugnaciondetalle = 'S';
                }
                else {
                    this.vis_medio_impugnaciondetalle = 'N';
                }
                if (data.acuerdoemp_fechacontestacion == '1') {
                    this.vis_acuerdoemp_fechacontestacion = 'S';
                }
                else {
                    this.vis_acuerdoemp_fechacontestacion = 'N';
                }

                if (data.acta_materia == '1') {
                    this.vis_acta_materia = 'S';
                }
                else {
                    this.vis_acta_materia = 'N';
                }

                if (data.acta_medidaseguridad == '1') {
                    this.vis_medidaseguridad_acta = 'S';
                }
                else {
                    this.vis_medidaseguridad_acta = 'N';
                }
                if (data.resolucion_medidaseguridad == '1') {
                    this.vis_medidaseguridad_resolucion = 'S';
                }
                else {
                    this.vis_medidaseguridad_resolucion = 'N';
                }

                if (data.acuerdoemp_medidaseguridad == '1') {
                    this.vis_acuerdoemp_medidaseguridad = 'S';
                }
                else {
                    this.vis_acuerdoemp_medidaseguridad = 'N';
                }

                if (data.acta_medidaurgente == '1') {
                    this.vis_medidaurgencia_acta = 'S';
                }
                else {
                    this.vis_medidaurgencia_acta = 'N';
                }

                if (data.acta_levantamientosello == '1') {
                    this.vis_levantamientosello_acta = 'S';
                }
                else {
                    this.vis_levantamientosello_acta = 'N';
                }
                if (data.resolucion_levantamiento == '1') {
                    this.vis_levantamiento_resolucion = 'S';
                }
                else {
                    this.vis_levantamiento_resolucion = 'N';
                }
                if (data.acuerdoemp_levantamiento == '1') {
                    this.vis_acuerdoemp_levantamiento = 'S';
                }
                else {
                    this.vis_acuerdoemp_levantamiento = 'N';
                }

                if (data.acuerdoemp_medidacorrectiva == '1') {
                    this.vis_acuerdoemp_medidacorrectiva = 'S';
                }
                else {
                    this.vis_acuerdoemp_medidacorrectiva = 'N';
                }
                if (data.resolucion_medidacorrectiva == '1') {
                    this.vis_medidacorrectiva_resolucion = 'S';
                }
                else {
                    this.vis_medidacorrectiva_resolucion = 'N';
                }


                if (data.otrosprroroga == '1') {
                    this.vis_otrosprroroga = 'S';
                }
                else {
                    this.vis_otrosprroroga = 'N';
                }
                if (data.otrosmedidas == '1') {
                    this.vis_otrosmedidas = 'S';
                }
                else {
                    this.vis_otrosmedidas = 'N';
                }
                if (data.otroscopias == '1') {
                    this.vis_otroscopias = 'S';
                }
                else {
                    this.vis_otroscopias = 'N';
                }
            }
            );

        },
        cargaCatalogos() {

            this.ProcedimientoAdministrativo.getDiaMedida().then(data => {
                this.dataSourceDiaMedida = data
            }
            );
            this.ProcedimientoAdministrativo.getCompetencia().then(data => {
                this.dataSourceCompetencia = data
            }
            );
            this.ProcedimientoAdministrativo.getMateria().then(data => {
                this.dataSourceMateria = data
            }
            );
            this.ProcedimientoAdministrativo.getTipoRespuesta().then(data => {
                this.dataSourceTipoRespuesta = data
            }
            );
            this.ProcedimientoAdministrativo.getMedioImpugnacionConsulta().then(data => {
                this.dataSourceMedioImpugnacionConsulta = data
            }
            );
            this.ProcedimientoAdministrativo.getClasificacion().then(data => {
                this.dataSourceClasificacion = data
            }
            );

        },
        /** Acta Inspeccion */
        verActa() {
            if (this.vis_actainspeccion === 'S') {
                this.vis_actainspeccion = 'N';
            }
            else {
                this.vis_actainspeccion = 'S';
            }
        },
        onActaInspeccionSeleccionada({ selectedRowsData }) {
            const accion = selectedRowsData[0];
            if (accion.medidaseguridad == '1') {
                this.vis_medidaseguridad_acta = 'S';
            }
            if (accion.medidaurgencia == '1') {
                this.vis_medidaurgencia_acta = 'S';
            }
            if (accion.levantamientosello == '1') {
                this.vis_levantamientosello_acta = 'S';
            }
            this.vis_acta_materia = 'S';
            this.v_acta = ' / Acta Inspección Seleccionada: ' + accion.noactainspeccion;
            this.vActaInspeccionId = accion.id;
            console.log('DATA ACCION ID SELECCIONADA:' + this.vActaInspeccionId);

        },
        consultaActaInspeccion(myJSON) {

            this.ProcedimientoAdministrativo.getActaInspeccion(myJSON).then(data => {
                this.dataSourceActaInspeccion = data;

            }
            );

        },

        agregarActaInspeccion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pnoexpediente: (e.data.noexpediente == undefined) ? '' : e.data.noexpediente,
                pordeninspeccion: (e.data.ordeninspeccion == undefined) ? '' : e.data.ordeninspeccion,
                pnoactainspeccion: (e.data.noactainspeccion == undefined) ? '' : e.data.noactainspeccion,
                pfechaoficio: (e.data.fechaoficio == undefined) ? '9999-01-01' : e.data.fechaoficio,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : e.data.fechanotificacion,
                pcompetenciaid: (e.data.competenciaid == undefined) ? 0 : e.data.competenciaid,
                pmateriaid: (e.data.materiaid == undefined) ? 0 : e.data.materiaid,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
                pmedidaseguridad: (e.data.medidaseguridad == undefined) ? '0' : e.data.medidaseguridad,
                pmedidaurgente: (e.data.medidaurgente == undefined) ? '0' : e.data.medidaurgente,
                plevantamientosello: (e.data.levantamientosello == undefined) ? '0' : e.data.levantamientosello,
            });

            this.ProcedimientoAdministrativo.createActaInspeccion(myJSON).then(data => {
                e.component.clearSelection();
                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                    e.component.clearSelection();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    e.component.clearSelection();

                }


            });


        },

        editarActaInspeccion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pnoexpediente: (e.data.noexpediente == undefined) ? '' : e.data.noexpediente,
                pordeninspeccion: (e.data.ordeninspeccion == undefined) ? '' : e.data.ordeninspeccion,
                pnoactainspeccion: (e.data.noactainspeccion == undefined) ? '' : e.data.noactainspeccion,
                pfechaoficio: (e.data.fechaoficio == '') ? '9999-01-01' : e.data.fechaoficio,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : e.data.fechanotificacion,
                pcompetenciaid: (e.data.competenciaid == undefined) ? 0 : e.data.competenciaid,
                pmateriaid: (e.data.materiaid == undefined) ? 0 : e.data.materiaid,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
                pmedidaseguridad: (e.data.medidaseguridad == undefined) ? '0' : e.data.medidaseguridad,
                pmedidaurgente: (e.data.medidaurgente == undefined) ? '0' : e.data.medidaurgente,
                plevantamientosello: (e.data.levantamientosello == undefined) ? '0' : e.data.levantamientosello,
            });

            this.ProcedimientoAdministrativo.updateActaInspeccion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                    e.component.clearSelection();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    e.component.clearSelection();

                }


            });


        },


        eliminarActaInspeccion(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteActaInspeccion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },
        // Medidas Seguridad Acta Inspeccion
        consultaMedidasSeguridadActaInspeccion() {
            let myJSON = JSON.stringify({
                pordentrabajoid: this.vidot,
                pproadm_actainspeccionid: this.vActaInspeccionId,
                pproadm_acuerdoemplazamientoid: 0,
                pproadm_resolucionid: 0,

            });
            this.ProcedimientoAdministrativo.getMedidaSeguridad(myJSON).then(data => {
                this.dataSourceMedidaSeguridadActa = data;
                this.dataSourceAcuerdoEmplazamientoMedidaSeguridad = data;
                this.dataSourceMedidaSeguridadResolucion = data;
            }
            );

        },
        agregarMedidaSeguridadActaInspeccion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pmedidaurgente: 0,
                pclasificacionid: (e.data.clasificacionid == undefined) ? 0 : e.data.clasificacionid,
                pdescripcion: (e.data.descripcion == undefined) ? '' : e.data.descripcion,
                plevantamientosello: 0,
                pproadm_actainspeccionid: this.vActaInspeccionId,
                pproadm_acuerdoemplazamientoid: 0,
                pproadm_resolucionid: 0,
            });




            if (this.vActaInspeccionId === null || this.vActaInspeccionId == undefined || this.vActaInspeccionId == '0') {

                notify({
                    message: 'Debe seleccionar una registro del acta de inspección',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 9000);
                this.cargaDatos();
            }
            else {

                this.ProcedimientoAdministrativo.createMedidaSeguridad(myJSON).then(data => {

                    if (data.err == 0) {

                        notify({
                            message: 'Registro Insertado',
                        }, 'success', 9000);

                        this.cargaDatos();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }


                });
            }//else

        },
        editarMedidaSeguridadActaInspeccion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pmedidaurgente: 0,
                pclasificacionid: (e.data.clasificacionid == undefined) ? 0 : e.data.clasificacionid,
                pdescripcion: (e.data.descripcion == undefined) ? '' : e.data.descripcion,
                plevantamientosello: 0
            });

            this.ProcedimientoAdministrativo.updateMedidaSeguridad(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },
        eliminarMedidaSeguridadActaInspeccion(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id
            });

            this.ProcedimientoAdministrativo.deleteMedidaSeguridad(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        // Medidas Urgencias Acta Inspeccion
        consultaMedidasUrgenciaActaInspeccion() {
            let myJSON = JSON.stringify({
                pordentrabajoid: this.vidot,
                pproadm_actainspeccionid: this.vActaInspeccionId,

            });
            this.ProcedimientoAdministrativo.getMedidaUrgente(myJSON).then(data => {
                this.dataSourceMedidaUrgenciaActa = data;

            }
            );

        },
        agregarMedidaUrgenciaActaInspeccion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pdescripcion: (e.data.descripcion == undefined) ? '' : e.data.descripcion,
                pproadm_actainspeccionid: this.vActaInspeccionId
            });




            if (this.vActaInspeccionId === null || this.vActaInspeccionId == undefined || this.vActaInspeccionId == '0') {

                notify({
                    message: 'Debe seleccionar una registro del acta de inspección',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 9000);
                this.cargaDatos();
            }
            else {

                this.ProcedimientoAdministrativo.createMedidaUrgente(myJSON).then(data => {

                    if (data.err == 0) {

                        notify({
                            message: 'Registro Insertado',
                        }, 'success', 9000);

                        this.cargaDatos();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }


                });
            }//else

        },
        editarMedidaUrgenciaActaInspeccion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pdescripcion: (e.data.descripcion == undefined) ? '' : e.data.descripcion,
                pproadm_actainspeccionid: this.vActaInspeccionId
            });

            this.ProcedimientoAdministrativo.updateMedidaUrgente(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },
        eliminarMedidaUrgenciaActaInspeccion(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id
            });

            this.ProcedimientoAdministrativo.deleteMedidaUrgente(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        // Levantamiento Sellos
        consultaLevantamientoSelloActaInspeccion() {
            let myJSON = JSON.stringify({
                pid: this.vidot,
                pproadm_actainspeccionid: this.vActaInspeccionId,
                pproadm_acuerdoemplazamientoid: 0,
                pproadm_resolucionid: 0,
            });
            this.ProcedimientoAdministrativo.getLevantamientoSello(myJSON).then(data => {
                this.dataSourceLevantamientoSelloActa = data;
                this.dataSourceAcuerdoEmplazamientoLevantamientoSello = data;
                this.dataSourceLevantamientoSelloResolucion = data;
            }
            );

        },
        agregarLevantamientoSelloActaInspeccion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pfechaoficio: (e.data.fechaoficio == undefined) ? '9999-01-01' : e.data.fechaoficio,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : e.data.fechanotificacion,
                pnoactalevantamientosello: (e.data.noactalevantamientosello == undefined) ? '' : e.data.noactalevantamientosello,
                pobservaciones: (e.data.observaciones == undefined) ? '' : e.data.observaciones,

                pproadm_actainspeccionid: this.vActaInspeccionId,

                pproadm_acuerdoemplazamientoid: 0,
                pproadm_resolucionid: 0,
            });




            if (this.vActaInspeccionId === null || this.vActaInspeccionId == undefined || this.vActaInspeccionId == '0') {

                notify({
                    message: 'Debe seleccionar una registro del acta de inspección',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 9000);
                this.cargaDatos();
            }
            else {

                this.ProcedimientoAdministrativo.createLevantamientoSello(myJSON).then(data => {

                    if (data.err == 0) {

                        notify({
                            message: 'Registro Insertado',
                        }, 'success', 9000);

                        this.cargaDatos();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }


                });
            }//else

        },
        editarLevantamientoSelloActaInspeccion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pfechaoficio: (e.data.fechaoficio == undefined) ? '9999-01-01' : e.data.fechaoficio,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : e.data.fechanotificacion,
                pnoactalevantamientosello: (e.data.noactalevantamientosello == undefined) ? '' : e.data.noactalevantamientosello,
                pobservaciones: (e.data.observaciones == undefined) ? '' : e.data.observaciones,

            });

            this.ProcedimientoAdministrativo.updateLevantamientoSello(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },
        eliminarLevantamientoSelloActaInspeccion(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id
            });

            this.ProcedimientoAdministrativo.deleteLevantamientoSello(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        // Materia Acta Inspeccion
        consultaActaInspeccionMateria() {
            let myJSON = JSON.stringify({
                pordentrabajoid: this.vidot,
                pproadm_actainspeccionid: this.vActaInspeccionId,

            });
            this.ProcedimientoAdministrativo.getActaInspeccionMateria(myJSON).then(data => {
                this.dataSourceActaMateria = data;
            }
            );

        },
        agregarActaInspeccionMateria(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_actainspeccionid: this.vActaInspeccionId,
                pmateriaid: (e.data.materiaid == undefined) ? 0 : e.data.materiaid,

            });


            if (this.vActaInspeccionId === null || this.vActaInspeccionId == undefined || this.vActaInspeccionId == '0') {

                notify({
                    message: 'Debe seleccionar una registro del acta de inspección',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 9000);
                this.cargaDatos();
            }
            else {

                this.ProcedimientoAdministrativo.createActaInspeccionMateria(myJSON).then(data => {

                    if (data.err == 0) {

                        notify({
                            message: 'Registro Insertado',
                        }, 'success', 9000);

                        this.cargaDatos();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }


                });
            }//else

        },
        editarActaInspeccionMateria(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_actainspeccionid: this.vActaInspeccionId,
                pmateriaid: (e.data.materiaid == undefined) ? 0 : e.data.materiaid,
            });

            this.ProcedimientoAdministrativo.updateActaInspeccionMateria(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },
        eliminarActaInspeccionMateria(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id
            });

            this.ProcedimientoAdministrativo.deleteActaInspeccionMateria(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        /** Contestacion Acta Inspeccion */
        verContestacionActa() {
            if (this.vis_contestacion === 'S') {
                this.vis_contestacion = 'N';
            }
            else {
                this.vis_contestacion = 'S';
            }
        },

        consultaContestacionActaInspeccion(myJSON) {
            console.log('json enviado ' + myJSON);
            this.ProcedimientoAdministrativo.getContestacionActaInspeccion(myJSON).then(data => {
                this.dataSourceContestacionActaInspeccion = data;

            }
            );

        },

        agregarContestacionActaInspeccion(e) {

            let id = this.vidot;
            let vconstestacionacta = e.data.constestacionacta == true ? 1 : 0;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pconstestacionacta: vconstestacionacta,
                pfechaingresoescrito: (e.data.fechaingresoescrito == undefined) ? '9999-01-01' : e.data.fechaingresoescrito,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,

            });

            this.ProcedimientoAdministrativo.createContestacionActaInspeccion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarContestacionActaInspeccion(e) {
            let id = this.vidot;
            let vconstestacionacta = e.data.constestacionacta == true ? 1 : 0;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pconstestacionacta: vconstestacionacta,
                pfechaingresoescrito: (e.data.fechaingresoescrito == '') ? '9999-01-01' : e.data.fechaingresoescrito,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,

            });



            this.ProcedimientoAdministrativo.updateContestacionActaInspeccion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarContestacionActaInspeccion(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteContestacionActaInspeccion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);
                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },



        /** Acuerdo Emplazamiento */
        verAcuerdo() {
            if (this.vis_acuerdo === 'S') {
                this.vis_acuerdo = 'N';
            }
            else {
                this.vis_acuerdo = 'S';
            }
        },
        onAcuerdoEmplazamientoSeleccionada({ selectedRowsData }) {
            const accion = selectedRowsData[0];

            if (accion.medidaseguridad == '1') {
                this.vis_acuerdoemp_medidaseguridad = 'S';
            }

            if (accion.levantamientosello == '1') {
                this.vis_acuerdoemp_levantamiento = 'S';
            }

            if (accion.medidacorrectiva == '1') {
                this.vis_acuerdoemp_medidacorrectiva = 'S';
            }
            this.vis_acuerdoemp_fechacontestacion = 'S';
            this.vActaAcuerdoEmplazamientoId = accion.id;
            console.log('DATA ACCION ID SELECCIONADA:' + this.vActaInspeccionId);

        },
        consultaAcuerdoEmplazamiento(myJSON) {
            console.log('json enviado ' + myJSON);
            this.ProcedimientoAdministrativo.getAcuerdoEmplazamiento(myJSON).then(data => {
                this.dataSourceAcuerdoEmplazamiento = data;

            }
            );

        },

        agregarAcuerdoEmplazamiento(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pfechaoficio: (e.data.fechaoficio == undefined) ? '9999-01-01' : e.data.fechaoficio,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : e.data.fechanotificacion,
                pfechacontestacion: (e.data.fechacontestacion == undefined) ? '9999-01-01' : e.data.fechacontestacion,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
                pmedidaseguridad: (e.data.medidaseguridad == undefined) ? '0' : e.data.medidaseguridad,
                pmedidacorrectiva: (e.data.medidacorrectiva == undefined) ? '0' : e.data.medidacorrectiva,
                plevantamientosello: (e.data.levantamientosello == undefined) ? '0' : e.data.levantamientosello,
            });

            this.ProcedimientoAdministrativo.createAcuerdoEmplazamiento(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                    e.component.clearSelection();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    e.component.clearSelection();

                }


            });


        },

        editarAcuerdoEmplazamiento(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pfechaoficio: (e.data.fechaoficio == '') ? '9999-01-01' : e.data.fechaoficio,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : e.data.fechanotificacion,
                pfechacontestacion: (e.data.fechacontestacion == '') ? '9999-01-01' : e.data.fechacontestacion,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
                pmedidaseguridad: (e.data.medidaseguridad == undefined) ? '0' : e.data.medidaseguridad,
                pmedidacorrectiva: (e.data.medidacorrectiva == undefined) ? '0' : e.data.medidacorrectiva,
                plevantamientosello: (e.data.levantamientosello == undefined) ? '0' : e.data.levantamientosello,

            });

            this.ProcedimientoAdministrativo.updateAcuerdoEmplazamiento(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                    e.component.clearSelection();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    e.component.clearSelection();

                }


            });


        },


        eliminarAcuerdoEmplazamiento(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteAcuerdoEmplazamiento(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        // Acuerdo Emplazamiento - Fecha Contestacion

        consultaAcuerdoEmplazamientoFechaContestacion() {
            let myJSON = JSON.stringify({
                pordentrabajoid: this.vidot,
                pproadm_acuerdoemplazamientoid: this.vActaAcuerdoEmplazamientoId,

            });
            this.ProcedimientoAdministrativo.getAcuerdoEmplazamientoFechaContestacion(myJSON).then(data => {
                this.dataSourceAcuerdoEmpFecha = data;

            }
            );

        },

        agregarAcuerdoEmplazamientoFechaContestacion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_acuerdoemplazamientoid: this.vActaAcuerdoEmplazamientoId,
                pfechacontestacion: (e.data.fechacontestacion == undefined) ? '9999-01-01' : e.data.fechacontestacion,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,

            });

            this.ProcedimientoAdministrativo.createAcuerdoEmplazamientoFechaContestacion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                    e.component.clearSelection();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    e.component.clearSelection();

                }


            });


        },

        editarAcuerdoEmplazamientoFechaContestacion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_acuerdoemplazamientoid: this.vActaAcuerdoEmplazamientoId,
                pfechacontestacion: (e.data.fechacontestacion == '') ? '9999-01-01' : e.data.fechacontestacion,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,

            });

            this.ProcedimientoAdministrativo.updateAcuerdoEmplazamientoFechaContestacion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                    e.component.clearSelection();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    e.component.clearSelection();

                }


            });


        },


        eliminarAcuerdoEmplazamientoFechaContestacion(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteAcuerdoEmplazamiento(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },



        // Acuerdo Emplazamiento - Medida Correctiva

        consultaMedidaCorrectivaAcuerdoEmplazamiento() {
            let myJSON = JSON.stringify({
                pordentrabajoid: this.vidot,
                pproadm_acuerdoemplazamientoid: this.vActaAcuerdoEmplazamientoId,

            });
            this.ProcedimientoAdministrativo.getMedidaCorrectiva(myJSON).then(data => {
                this.dataSourceAcuerdoEmplazamientoMedidaCorrectiva = data;
                this.dataSourceMedidaCorrectivaResolucion = data;
            }
            );

        },
        agregarMedidaCorrectivaAcuerdoEmplazamiento(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pdescripcion: (e.data.descripcion == undefined) ? '' : e.data.descripcion,
                pdiasid: (e.data.diasid == undefined) ? '0' : e.data.diasid,
                pproadm_acuerdoemplazamientoid: this.vActaAcuerdoEmplazamientoId,
                pdias: (e.data.dias == undefined) ? '0' : e.data.dias,
            });



            if (this.vActaAcuerdoEmplazamientoId === null || this.vActaAcuerdoEmplazamientoId == undefined || this.vActaAcuerdoEmplazamientoId == '0') {

                notify({
                    message: 'Debe seleccionar una registro del acuerdo de emplazamiento',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 9000);
                this.cargaDatos();
            }
            else {

                this.ProcedimientoAdministrativo.createMedidaCorrectiva(myJSON).then(data => {

                    if (data.err == 0) {

                        notify({
                            message: 'Registro Insertado',
                        }, 'success', 9000);

                        this.cargaDatos();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }


                });
            }//else

        },
        editarMedidaCorrectivaAcuerdoEmplazamiento(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pdescripcion: (e.data.descripcion == undefined) ? '' : e.data.descripcion,
                pdiasid: (e.data.diasid == undefined) ? '0' : e.data.diasid,
                pproadm_acuerdoemplazamientoid: 0,
                pdias: (e.data.dias == undefined) ? '0' : e.data.dias,
            });

            this.ProcedimientoAdministrativo.updateMedidaCorrectiva(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },
        eliminarMedidaCorrectivaAcuerdoEmplazamiento(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id
            });

            this.ProcedimientoAdministrativo.deleteMedidaCorrectiva(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        // Medidas Seguridad Acuerdo Emplazamiento
        /** 
         * //SE UTILIZARA EL DE ACTA INSPECCION
        consultaMedidaSeguridadAcuerdoEmplazamiento() {
            let myJSON = JSON.stringify({
               pordentrabajoid :this.vidot,
               pproadm_actainspeccionid :this.vActaInspeccionId,
               pproadm_acuerdoemplazamientoid :0,
               pproadm_resolucionid :0,
              
           });
            this.ProcedimientoAdministrativo.getMedidaSeguridad(myJSON).then(data => {
                this.dataSourceAcuerdoEmplazamientoMedidaSeguridad = data;
                 
                }
             );
          
        },
        */
        agregarMedidaSeguridadAcuerdoEmplazamiento(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pmedidaurgente: 0,
                pclasificacionid: (e.data.clasificacionid == undefined) ? 0 : e.data.clasificacionid,
                pdescripcion: (e.data.descripcion == undefined) ? '' : e.data.descripcion,
                plevantamientosello: 0,
                pproadm_actainspeccionid: 0,
                pproadm_acuerdoemplazamientoid: this.vActaAcuerdoEmplazamientoId,
                pproadm_resolucionid: 0,
            });




            if (this.vActaAcuerdoEmplazamientoId === null || this.vActaAcuerdoEmplazamientoId == undefined || this.vActaAcuerdoEmplazamientoId == '0') {

                notify({
                    message: 'Debe seleccionar una registro del acuerdo de emplazamiento',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 9000);
                this.cargaDatos();
            }
            else {

                this.ProcedimientoAdministrativo.createMedidaSeguridad(myJSON).then(data => {

                    if (data.err == 0) {

                        notify({
                            message: 'Registro Insertado',
                        }, 'success', 9000);

                        this.cargaDatos();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }


                });
            }//else

        },
        editarMedidaSeguridadAcuerdoEmplazamiento(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pmedidaurgente: 0,
                pclasificacionid: (e.data.clasificacionid == undefined) ? 0 : e.data.clasificacionid,
                pdescripcion: (e.data.descripcion == undefined) ? '' : e.data.descripcion,
                plevantamientosello: 0
            });

            this.ProcedimientoAdministrativo.updateMedidaSeguridad(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },
        eliminarMedidaSeguridadAcuerdoEmplazamiento(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id
            });

            this.ProcedimientoAdministrativo.deleteMedidaSeguridad(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        // Levantamiento Sellos
        // Se utilizara el de acta de inspeccion
        /**
        consultaLevantamientoSelloAcuerdoEmplazamiento() {
             let myJSON = JSON.stringify({
                 pid :this.vidot,
                pproadm_actainspeccionid :this.vActaInspeccionId,
                pproadm_acuerdoemplazamientoid :0,
                pproadm_resolucionid :0,
            });
             this.ProcedimientoAdministrativo.getLevantamientoSello(myJSON).then(data => {
                 this.dataSourceAcuerdoEmplazamientoLevantamientoSello = data;
                  
                 }
              );
           
         },
          */
        agregarLevantamientoSelloAcuerdoEmplazamiento(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pfechaoficio: (e.data.fechaoficio == undefined) ? '9999-01-01' : e.data.fechaoficio,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : e.data.fechanotificacion,
                pnoactalevantamientosello: (e.data.noactalevantamientosello == undefined) ? '' : e.data.noactalevantamientosello,
                pobservaciones: (e.data.observaciones == undefined) ? '' : e.data.observaciones,

                pproadm_actainspeccionid: 0,

                pproadm_acuerdoemplazamientoid: this.vActaAcuerdoEmplazamientoId,
                pproadm_resolucionid: 0,
            });




            if (this.vActaAcuerdoEmplazamientoId === null || this.vActaAcuerdoEmplazamientoId == undefined || this.vActaAcuerdoEmplazamientoId == '0') {

                notify({
                    message: 'Debe seleccionar una registro del acuerdo de emplazamiento',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 9000);
                this.cargaDatos();
            }
            else {

                this.ProcedimientoAdministrativo.createLevantamientoSello(myJSON).then(data => {

                    if (data.err == 0) {

                        notify({
                            message: 'Registro Insertado',
                        }, 'success', 9000);

                        this.cargaDatos();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }


                });
            }//else

        },
        editarLevantamientoSelloAcuerdoEmplazamiento(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pfechaoficio: (e.data.fechaoficio == undefined) ? '9999-01-01' : e.data.fechaoficio,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : e.data.fechanotificacion,
                pnoactalevantamientosello: (e.data.noactalevantamientosello == undefined) ? '' : e.data.noactalevantamientosello,
                pobservaciones: (e.data.observaciones == undefined) ? '' : e.data.observaciones,

            });

            this.ProcedimientoAdministrativo.updateLevantamientoSello(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },
        eliminarLevantamientoSelloAcuerdoEmplazamiento(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id
            });

            this.ProcedimientoAdministrativo.deleteLevantamientoSello(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },



        /** otros */
        verOtros() {
            if (this.vis_otros === 'S') {
                this.vis_otros = 'N';
            }
            else {
                this.vis_otros = 'S';
            }
        },
        onOtrosSeleccionada({ selectedRowsData }) {
            const accion = selectedRowsData[0];
            if (accion.tiporespuestaid == '3') {
                this.vis_otrosprroroga = 'S';
            }

            if (accion.tiporespuestaid == '4') {
                this.vis_otrosmedidas = 'S';
            }

            if (accion.tiporespuestaid == '5') {
                this.vis_otroscopias = 'S';
            }

            this.vOtrosId = accion.id;

            console.log('DATA OTROS ID SELECCIONADA:' + this.vOtrosId);
            console.log('DATA OTROS TIPO RESPUESTA SELECCIONADA:' + accion.tiporespuestaid);
        },

        consultaOtros(myJSON) {
            console.log('json enviado ' + myJSON);
            this.ProcedimientoAdministrativo.getOtros(myJSON).then(data => {
                this.dataSourceOtros = data;

            }
            );

        },

        agregarOtros(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                ptiporespuestaid: (e.data.tiporespuestaid == undefined) ? '0' : e.data.tiporespuestaid,


            });

            this.ProcedimientoAdministrativo.createOtros(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarOtros(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                ptiporespuestaid: (e.data.tiporespuestaid == undefined) ? '0' : e.data.tiporespuestaid,

            });

            this.ProcedimientoAdministrativo.updateOtros(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarOtros(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteOtros(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        // Otros Prorroga
        consultaOtrosProrroga() {
            let myJSON = JSON.stringify({
                pproadm_otros_id: this.vOtrosId,
                pordentrabajoid: this.vidot,
            });
            this.ProcedimientoAdministrativo.getOtrosProrroga(myJSON).then(data => {
                this.dataSourceOtrosProrroga = data;

            }
            );

        },
        agregarOtrosProrroga(e) {

            if (this.vOtrosId == 0) {
                notify({
                    message: 'No ha seleccion un elemento, favor de seleccionar y volver a intentar',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 9000);
            }
            else {

                let myJSON = JSON.stringify({
                    pordentrabajoid: this.vidot,
                    pproadm_otros_id: this.vOtrosId,
                    pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : e.data.fechaacuse,
                    pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : e.data.fechanotificacion,
                    pfechavencimiento: (e.data.fechavencimiento == undefined) ? '9999-01-01' : e.data.fechavencimiento,
                    pterminootorgado: (e.data.terminootorgado == undefined) ? '0' : e.data.terminootorgado,
                    pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,

                });

                this.ProcedimientoAdministrativo.createOtrosProrroga(myJSON).then(data => {

                    if (data.err == 0) {

                        notify({
                            message: 'Registro Insertado',
                        }, 'success', 9000);

                        this.cargaDatos();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }


                });

            }//else

        },
        editarOtrosProrroga(e) {



            let myJSON = JSON.stringify({
                pid: e.data.id,
                pproadm_otros_id: this.vOtrosId,
                pfechaacuse: (e.data.fechaacuse == '') ? '9999-01-01' : e.data.fechaacuse,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : e.data.fechanotificacion,
                pfechavencimiento: (e.data.fechavencimiento == '') ? '9999-01-01' : e.data.fechavencimiento,
                pterminootorgado: (e.data.terminootorgado == undefined) ? '0' : e.data.terminootorgado,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,

            });

            this.ProcedimientoAdministrativo.updateOtrosProrroga(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });



        },
        eliminarOtrosProrroga(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,

            });

            this.ProcedimientoAdministrativo.deleteOtrosProrroga(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });



        },

        // Otros Medidas
        consultaOtrosMedidas() {
            let myJSON = JSON.stringify({
                pproadm_otros_id: this.vOtrosId,
                pordentrabajoid: this.vidot,
            });
            this.ProcedimientoAdministrativo.getVerificacionMedida(myJSON).then(data => {
                this.dataSourceOtrosMedidas = data;

            }
            );

        },
        agregarOtrosMedidas(e) {

            if (this.vOtrosId == 0) {
                notify({
                    message: 'No ha seleccion un elemento, favor de seleccionar y volver a intentar',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 9000);
            }
            else {

                let myJSON = JSON.stringify({
                    pordentrabajoid: this.vidot,
                    pproadm_otros_id: this.vOtrosId,
                    pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : e.data.fechaacuse,
                    pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                    pfechaoficio: (e.data.fechaoficio == undefined) ? '9999-01-01' : e.data.fechaoficio,
                    pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : e.data.fechanotificacion,
                    pfechavisita: (e.data.fechavisita == undefined) ? '9999-01-01' : e.data.fechavisita,
                    pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,


                });

                this.ProcedimientoAdministrativo.createVerificacionMedida(myJSON).then(data => {

                    if (data.err == 0) {

                        notify({
                            message: 'Registro Insertado',
                        }, 'success', 9000);

                        this.cargaDatos();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }


                });

            }//else

        },
        editarOtrosMedidas(e) {



            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: this.vidot,
                pproadm_otros_id: this.vOtrosId,
                pfechaacuse: (e.data.fechaacuse == '') ? '9999-01-01' : e.data.fechaacuse,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pfechaoficio: (e.data.fechaoficio == '') ? '9999-01-01' : e.data.fechaoficio,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : e.data.fechanotificacion,
                pfechavisita: (e.data.fechavisita == '') ? '9999-01-01' : e.data.fechavisita,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,

            });

            this.ProcedimientoAdministrativo.updateVerificacionMedida(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });



        },
        eliminarOtrosMedidas(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,

            });

            this.ProcedimientoAdministrativo.deleteVerificacionMedida(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });



        },

        // Otros Copias
        consultaOtrosCopias() {
            let myJSON = JSON.stringify({
                pproadm_otros_id: this.vOtrosId,
                pordentrabajoid: this.vidot,
            });
            this.ProcedimientoAdministrativo.getCopiaCertificada(myJSON).then(data => {
                this.dataSourceOtrosCopias = data;

            }
            );

        },
        agregarOtrosCopias(e) {

            if (this.vOtrosId == 0) {
                notify({
                    message: 'No ha seleccion un elemento, favor de seleccionar y volver a intentar',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 9000);
            }
            else {

                let myJSON = JSON.stringify({
                    pordentrabajoid: this.vidot,
                    pproadm_otros_id: this.vOtrosId,
                    pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : e.data.fechaacuse,
                    pnooficio: (e.data.nooficio == undefined) ? '9999-01-01' : e.data.nooficio,
                    pfechaoficio: (e.data.fechaoficio == undefined) ? '9999-01-01' : e.data.fechaoficio,
                    pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : e.data.fechanotificacion,
                    pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,

                });

                this.ProcedimientoAdministrativo.createCopiaCertificada(myJSON).then(data => {

                    if (data.err == 0) {

                        notify({
                            message: 'Registro Insertado',
                        }, 'success', 9000);

                        this.cargaDatos();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }


                });

            }//else

        },
        editarOtrosCopias(e) {



            let myJSON = JSON.stringify({
                pid: e.data.id,
                pproadm_otros_id: this.vOtrosId,
                pordentrabajoid: this.vidot,
                pfechaacuse: (e.data.fechaacuse == '') ? '9999-01-01' : e.data.fechaacuse,
                pnooficio: (e.data.nooficio == undefined) ? '9999-01-01' : e.data.nooficio,
                pfechaoficio: (e.data.fechaoficio == '') ? '9999-01-01' : e.data.fechaoficio,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : e.data.fechanotificacion,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,

            });

            this.ProcedimientoAdministrativo.updateCopiaCertificada(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });



        },
        eliminarOtrosCopias(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,

            });

            this.ProcedimientoAdministrativo.deleteCopiaCertificada(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });



        },

        /** Alegatos */
        verAlegatos() {
            if (this.vis_alegatos === 'S') {
                this.vis_alegatos = 'N';
            }
            else {
                this.vis_alegatos = 'S';
            }
        },
        consultaAlegatos(myJSON) {
            console.log('json enviado ' + myJSON);
            this.ProcedimientoAdministrativo.getAlegatos(myJSON).then(data => {
                this.dataSourceAlegatos = data;

            }
            );

        },

        agregarAlegatos(e) {


            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                ptiempootorgado: (e.data.tiempootorgado == undefined) ? '0' : e.data.tiempootorgado,
                pfechaacuerdo: (e.data.fechaacuerdo == undefined) ? '9999-01-01' : e.data.fechaacuerdo,
                ppcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,

            });

            this.ProcedimientoAdministrativo.createAlegatos(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarAlegatos(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                ptiempootorgado: (e.data.tiempootorgado == undefined) ? '0' : e.data.tiempootorgado,
                pfechaacuerdo: (e.data.fechaacuerdo == '') ? '9999-01-01' : e.data.fechaacuerdo,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,

            });

            this.ProcedimientoAdministrativo.updateAlegatos(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarAlegatos(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteAlegatos(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        /** Resolucion */
        verResolucion() {
            if (this.vis_resolucion === 'S') {
                this.vis_resolucion = 'N';
            }
            else {
                this.vis_resolucion = 'S';
            }
        },
        onResolucionSeleccionada({ selectedRowsData }) {
            const accion = selectedRowsData[0];
            if (accion.medidaseguridad == '1') {
                this.vis_medidaseguridad_resolucion = 'S';
            }
            if (accion.medidacorrectiva == '1') {
                this.vis_medidacorrectiva_resolucion = 'S';
            }
            if (accion.levantamientosello == '1') {
                this.vis_levantamiento_resolucion = 'S';
            }
            this.vResolucionId = accion.id;
            console.log('DATA ACCION ID SELECCIONADA:' + this.vResolucionId);

        },
        consultaResolucion(myJSON) {

            this.ProcedimientoAdministrativo.getResolucion(myJSON).then(data => {
                this.dataSourceResolucion = data;

            }
            );

        },
        agregarMedidaCorrectivaResolucion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pdescripcion: (e.data.descripcion == undefined) ? '' : e.data.descripcion,
                pdiasid: (e.data.diasid == undefined) ? '0' : e.data.diasid,
                pproadm_acuerdoemplazamientoid: 0,
                pdias: (e.data.dias == undefined) ? '0' : e.data.dias,
            });



            

                this.ProcedimientoAdministrativo.createMedidaCorrectiva(myJSON).then(data => {

                    if (data.err == 0) {

                        notify({
                            message: 'Registro Insertado',
                        }, 'success', 9000);

                        this.cargaDatos();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }


                });
           

            },
            editarMedidaCorrectivaResolucion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pdescripcion: (e.data.descripcion == undefined) ? '' : e.data.descripcion,
                pdiasid: (e.data.diasid == undefined) ? '0' : e.data.diasid,
                pproadm_acuerdoemplazamientoid: 0,
                pdias: (e.data.dias == undefined) ? '0' : e.data.dias,
            });

            this.ProcedimientoAdministrativo.updateMedidaCorrectiva(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


            },
        agregarResolucion(e) {


            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pmultaimpuesta: (e.data.multaimpuesta == undefined) ? '0' : e.data.multaimpuesta,
                pfecharesolucion: (e.data.fecharesolucion == undefined) ? '9999-01-01' : moment(e.data.fecharesolucion).format('YYYY-MM-DD') ,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' :  moment(e.data.fechanotificacion).format('YYYY-MM-DD') ,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
                pmedidaseguridad: (e.data.medidaseguridad == undefined) ? '0' : e.data.medidaseguridad,
                pmedidacorrectiva: (e.data.medidacorrectiva == undefined) ? '0' : e.data.medidacorrectiva,
                plevantamientosello: (e.data.levantamientosello == undefined) ? '0' : e.data.levantamientosello,

               
            });

            this.ProcedimientoAdministrativo.createResolucion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarResolucion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pmultaimpuesta: (e.data.multaimpuesta == undefined) ? '0' : e.data.multaimpuesta,
              

                pfecharesolucion: (e.data.fecharesolucion == '') ? '9999-01-01' : moment(e.data.fecharesolucion).format('YYYY-MM-DD') ,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' :  moment(e.data.fechanotificacion).format('YYYY-MM-DD') ,

                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
                pmedidaseguridad: (e.data.medidaseguridad == undefined) ? '0' : e.data.medidaseguridad,
                pmedidacorrectiva: (e.data.medidacorrectiva == undefined) ? '0' : e.data.medidacorrectiva,
                plevantamientosello: (e.data.levantamientosello == undefined) ? '0' : e.data.levantamientosello,
            });

            this.ProcedimientoAdministrativo.updateResolucion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        editarMedidaSeguridadReiterar(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                preiterar:  (e.data.reiterar == undefined) ? 0 : e.data.reiterar,
                plevantar:  (e.data.levantar == undefined) ? 0 : e.data.levantar,
            });

            this.ProcedimientoAdministrativo.updateMedidaSeguridadReiterar(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


            },

        eliminarResolucion(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteResolucion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        agregarLevantamientoSelloResolucion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pfechaoficio: (e.data.fechaoficio == undefined) ? '9999-01-01' : moment(e.data.fechaoficio).format('YYYY-MM-DD'),
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnoactalevantamientosello: (e.data.noactalevantamientosello == undefined) ? '' : e.data.noactalevantamientosello,
                pobservaciones: (e.data.observaciones == undefined) ? '' : e.data.observaciones,

                pproadm_actainspeccionid: 0,

                pproadm_acuerdoemplazamientoid: 0,
                pproadm_resolucionid: 0,
            });




             

                this.ProcedimientoAdministrativo.createLevantamientoSello(myJSON).then(data => {

                    if (data.err == 0) {

                        notify({
                            message: 'Registro Insertado',
                        }, 'success', 9000);

                        this.cargaDatos();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }


                });
           

            },
            editarLevantamientoSelloResolucion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pnoactalevantamientosello: (e.data.noactalevantamientosello == undefined) ? '' : e.data.noactalevantamientosello,
                pobservaciones: (e.data.observaciones == undefined) ? '' : e.data.observaciones,
                pfechaoficio: (e.data.fechaoficio == '') ? '9999-01-01' : moment(e.data.fechaoficio).format('YYYY-MM-DD'),
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
            });

            this.ProcedimientoAdministrativo.updateLevantamientoSello(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


            },

        /** Medio Impugnacion */

        verMedio() {
            if (this.vis_medioimp === 'S') {
                this.vis_medioimp = 'N';
            }
            else {
                this.vis_medioimp = 'S';
            }
        },

        onMedioImpugnacionSeleccionada(e) {
            console.log('XXX:' + e);


        },
        consultaMedioImpugnacion(myJSON) {

            this.ProcedimientoAdministrativo.getMedidaImpugnacion(myJSON).then(data => {
                this.dataSourceMedioImpugnacion = data;

            }
            );

        },

        agregarMedioImpugnacion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pmedioimpugnacionid: (e.data.medioimpugnacionid == undefined) ? '0' : e.data.medioimpugnacionid,


            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacion(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pmedioimpugnacionid: (e.data.medioimpugnacionid == undefined) ? '0' : e.data.medioimpugnacionid,

            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacion(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacion(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        // Medio Impugnacion Detalle
        onMedioImpugnacionSeleccionadaDetalle({ selectedRowsData }) {
            const accion = selectedRowsData[0];

            this.vmedioimpugnaciondetalleid = accion.id;

            // JCA
            if (accion.medioimpugnaciondetalleid == '22') {
                this.vis_medio_impugnaciondetalleJCA = 'S';
                this.vis_medio_impugnaciondetalleJCA_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '23') {
                this.vis_medio_impugnaciondetalleJCB = 'S';
                this.vis_medio_impugnaciondetalleJCB_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '24') {
                this.vis_medio_impugnaciondetalleJCC = 'S';
                this.vis_medio_impugnaciondetalleJCC_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '25') {
                this.vis_medio_impugnaciondetalleJCD = 'S';
                this.vis_medio_impugnaciondetalleJCD_MED = 'S';
            }


            // JNA
            if (accion.medioimpugnaciondetalleid == '17') {
                this.vis_medio_impugnaciondetalleJNA = 'S';
                this.vis_medio_impugnaciondetalleJNA_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '18') {
                this.vis_medio_impugnaciondetalleJNB = 'S';
                this.vis_medio_impugnaciondetalleJNB_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '19') {
                this.vis_medio_impugnaciondetalleJNC = 'S';
                this.vis_medio_impugnaciondetalleJNC_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '20') {
                this.vis_medio_impugnaciondetalleJND = 'S';
                this.vis_medio_impugnaciondetalleJND_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '21') {
                this.vis_medio_impugnaciondetalleJNE = 'S';
                this.vis_medio_impugnaciondetalleJNE_MED = 'S';
            }


            //RRA
            if (accion.medioimpugnaciondetalleid == '13') {
                this.vis_medio_impugnaciondetalleRRA = 'S';
                this.vis_medio_impugnaciondetalleRRA_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '14') {
                this.vis_medio_impugnaciondetalleRRB = 'S';
                this.vis_medio_impugnaciondetalleRRB_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '15') {
                this.vis_medio_impugnaciondetalleRRC = 'S';
                this.vis_medio_impugnaciondetalleRRC_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '16') {
                this.vis_medio_impugnaciondetalleRRD = 'S';
                this.vis_medio_impugnaciondetalleRRD_MED = 'S';
            }

            //RMA
            if (accion.medioimpugnaciondetalleid == '7') {
                this.vis_medio_impugnaciondetalleRMA = 'S';
                this.vis_medio_impugnaciondetalleRMA_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '8') {
                this.vis_medio_impugnaciondetalleRMB = 'S';
                this.vis_medio_impugnaciondetalleRMB_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '9') {
                this.vis_medio_impugnaciondetalleRMC = 'S';
                this.vis_medio_impugnaciondetalleRMC_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '10') {
                this.vis_medio_impugnaciondetalleRMD = 'S';
                this.vis_medio_impugnaciondetalleRMD_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '11') {
                this.vis_medio_impugnaciondetalleRME = 'S';
                this.vis_medio_impugnaciondetalleRME_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '12') {
                this.vis_medio_impugnaciondetalleRMF = 'S';
                this.vis_medio_impugnaciondetalleRMF_MED = 'S';
            }

            // CMA
            if (accion.medioimpugnaciondetalleid == '1') {
                this.vis_medio_impugnaciondetalleCMA = 'S';
                this.vis_medio_impugnaciondetalleCMA_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '2') {
                this.vis_medio_impugnaciondetalleCMB = 'S';
                this.vis_medio_impugnaciondetalleCMB_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '3') {
                this.vis_medio_impugnaciondetalleCMC = 'S';
                this.vis_medio_impugnaciondetalleCMC_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '4') {
                this.vis_medio_impugnaciondetalleCMD = 'S';
                this.vis_medio_impugnaciondetalleCMD_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '5') {
                this.vis_medio_impugnaciondetalleCME = 'S';
                this.vis_medio_impugnaciondetalleCME_MED = 'S';
            }
            if (accion.medioimpugnaciondetalleid == '6') {
                this.vis_medio_impugnaciondetalleCMF = 'S';
                this.vis_medio_impugnaciondetalleCMF_MED = 'S';
            }

        },
        consultaMedioImpugnacionDetalle() {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pproadm_medioimpugnacionid: this.vMedioImpugnacionId,
                pmedioimpugnaciondetalleid: 0,
                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionDetalle(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionDetalle = data;


            }
            );

        },
        consultaMedioImpugnacionDetalleConsulta() {


            let myJSON = JSON.stringify({
                pmedioimpugnacionid: this.vMedioImpugnacionCatalogoId,


            });

            this.ProcedimientoAdministrativo.getMedioImpugnacionConsultaDetalle(myJSON).then(data => {
                this.dataSourceMedioImpugnacionDetalleConsulta = data;


            }
            );

        },
        agregarMedioImpugnacionDetalle(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnacionid: this.vMedioImpugnacionId,
                pmedioimpugnaciondetalleid: (e.data.medioimpugnaciondetalleid == undefined) ? '0' : e.data.medioimpugnaciondetalleid,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionDetalle(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionDetalle(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnacionid: this.vMedioImpugnacionId,
                pmedioimpugnaciondetalleid: (e.data.medioimpugnaciondetalleid == undefined) ? '0' : e.data.medioimpugnaciondetalleid,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionDetalle(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionDetalle(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionDetalle(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        // MEDIO IMPUGNACION JUICIO AMPARO - A
        consultaMedioImpugnacionjca() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionjca(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionJCA = data;


            }
            );

        },

        agregarMedioImpugnacionjca(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse.format('YYYY-MM-DD')),

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionjca(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionjca(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse.format('YYYY-MM-DD')),
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionjca(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionjca(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionjca(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },





        // MEDIO IMPUGNACION JUICIO AMPARO - B
        consultaMedioImpugnacionjcb() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionjcb(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionJCB = data;


            }
            );

        },

        agregarMedioImpugnacionjcb(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion.format('YYYY-MM-DD')),
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionjcb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionjcb(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion.format('YYYY-MM-DD')),
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionjcb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionjcb(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionjcb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },




        // MEDIO IMPUGNACION JUICIO AMPARO - C
        consultaMedioImpugnacionjcc() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionjcc(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionJCC = data;


            }
            );

        },

        agregarMedioImpugnacionjcc(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pfechaemision: (e.data.fechaemision == undefined) ? '9999-01-01' : moment(e.data.fechaemision.format('YYYY-MM-DD')),
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion.format('YYYY-MM-DD')),
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionjcc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionjcc(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pnooficio: (e.data.nooficio == '') ? '' : e.data.nooficio,
                pfechaemision: (e.data.fechaemision == '') ? '9999-01-01' : moment(e.data.fechaemision.format('YYYY-MM-DD')),
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion.format('YYYY-MM-DD')),
                pcomentarios: (e.data.comentarios == '') ? '' : e.data.comentarios,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionjcc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionjcc(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionjcc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        // MEDIO IMPUGNACION JUICIO AMPARO - d
        consultaMedioImpugnacionjcd() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionjcd(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionJCD = data;


            }
            );

        },

        agregarMedioImpugnacionjcd(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pfechaemision: (e.data.fechaemision == undefined) ? '9999-01-01' : moment(e.data.fechaemision.format('YYYY-MM-DD')),
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion.format('YYYY-MM-DD')),
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionjcd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionjcd(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pnooficio: (e.data.nooficio == '') ? '' : e.data.nooficio,
                pfechaemision: (e.data.fechaemision == '') ? '9999-01-01' : moment(e.data.fechaemision.format('YYYY-MM-DD')),
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion.format('YYYY-MM-DD')),
                pcomentarios: (e.data.comentarios == '') ? '' : e.data.comentarios,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionjcd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionjcd(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionjcd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },





        // MEDIO IMPUGNACION JUICIO NULIDAD - A
        consultaMedioImpugnacionjna() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionjna(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionJNA = data;


            }
            );

        },

        agregarMedioImpugnacionjna(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse.format('YYYY-MM-DD')),

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionjna(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionjna(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse.format('YYYY-MM-DD')),
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionjna(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionjna(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionjna(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },





        // MEDIO IMPUGNACION JUICIO NULIDAD - B
        consultaMedioImpugnacionjnb() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionjnb(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionJNB = data;


            }
            );

        },

        agregarMedioImpugnacionjnb(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse.format('YYYY-MM-DD')),
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionjnb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionjnb(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse.format('YYYY-MM-DD')),
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionjnb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionjnb(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionjnb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },




        // MEDIO IMPUGNACION JUICIO NULIDAD - C
        consultaMedioImpugnacionjnc() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionjnc(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionJNC = data;


            }
            );

        },

        agregarMedioImpugnacionjnc(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion.format('YYYY-MM-DD')),
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionjnc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionjnc(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion.format('YYYY-MM-DD')),
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionjnc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionjnc(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionjnc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },



        // MEDIO IMPUGNACION JUICIO NULIDAD - D
        consultaMedioImpugnacionjnd() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionjnd(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionJND = data;


            }
            );

        },

        agregarMedioImpugnacionjnd(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pnoexpediente: (e.data.noexpediente == undefined) ? '' : e.data.noexpediente,
                pfechaemision: (e.data.fechaemision == undefined) ? '9999-01-01' : moment(e.data.fechaemision.format('YYYY-MM-DD')),
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion.format('YYYY-MM-DD')),

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionjnd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionjnd(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pnooficio: (e.data.nooficio == undefined) ? '' : e.data.nooficio,
                pnoexpediente: (e.data.noexpediente == undefined) ? '' : e.data.noexpediente,
                pfechaemision: (e.data.fechaemision == '') ? '9999-01-01' : moment(e.data.fechaemision.format('YYYY-MM-DD')),
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion.format('YYYY-MM-DD')),
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionjnd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionjnd(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionjnd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },




        // MEDIO IMPUGNACION JUICIO NULIDAD - E
        consultaMedioImpugnacionjne() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionjne(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionJNE = data;


            }
            );

        },

        agregarMedioImpugnacionjne(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfecharesolucion: (e.data.fecharesolucion == undefined) ? '9999-01-01' : moment(e.data.fecharesolucion.format('YYYY-MM-DD')),
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion.format('YYYY-MM-DD')),
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios,
            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionjne(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionjne(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfecharesolucion: (e.data.fecharesolucion == '') ? '9999-01-01' : moment(e.data.fecharesolucion.format('YYYY-MM-DD')),
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion.format('YYYY-MM-DD')),
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentarios
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionjne(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionjne(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionjne(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },





        // MEDIO IMPUGNACION RECURSO REVISION - A
        consultaMedioImpugnacionrra() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionrra(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionRRA = data;


            }
            );

        },

        agregarMedioImpugnacionrra(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse).format('YYYY-MM-DD'),

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionrra(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionrra(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse).format('YYYY-MM-DD'),
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionrra(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionrra(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionrra(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },



        // MEDIO IMPUGNACION RECURSO REVISION - B
        consultaMedioImpugnacionrrb() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionrrb(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionRRB = data;


            }
            );

        },

        agregarMedioImpugnacionrrb(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnorecursorevision: (e.data.norecursorevision == undefined) ? '' : e.data.norecursorevision

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionrrb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionrrb(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnorecursorevision: (e.data.norecursorevision == undefined) ? '' : e.data.norecursorevision
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionrrb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionrrb(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionrrb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },



        // MEDIO IMPUGNACION RECURSO REVISION - C
        consultaMedioImpugnacionrrc() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionrrc(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionRRC = data;


            }
            );

        },

        agregarMedioImpugnacionrrc(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnotificacion: (e.data.notificacion == undefined) ? '' : e.data.notificacion,
                pfechavencimiento: (e.data.fechavencimiento == undefined) ? '9999-01-01' : moment(e.data.fechavencimiento).format('YYYY-MM-DD'),
                pfechacuse: (e.data.fechacuse == undefined) ? '9999-01-01' : moment(e.data.fechacuse).format('YYYY-MM-DD'),
            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionrrc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionrrc(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnotificacion: (e.data.notificacion == undefined) ? '' : e.data.notificacion,
                pfechavencimiento: (e.data.fechavencimiento == '') ? '9999-01-01' : moment(e.data.fechavencimiento).format('YYYY-MM-DD'),
                pfechacuse: (e.data.fechacuse == '') ? '9999-01-01' : moment(e.data.fechacuse).format('YYYY-MM-DD'),
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionrrc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionrrc(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionrrc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        // MEDIO IMPUGNACION RECURSO REVISION - D
        consultaMedioImpugnacionrrd() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionrrd(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionRRD = data;


            }
            );

        },

        agregarMedioImpugnacionrrd(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfecharesolucion: (e.data.fecharesolucion == undefined) ? '9999-01-01' : moment(e.data.fecharesolucion).format('YYYY-MM-DD'),
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentario
            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionrrd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionrrd(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfecharesolucion: (e.data.fecharesolucion == '') ? '9999-01-01' : moment(e.data.fecharesolucion).format('YYYY-MM-DD'),
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pcomentarios: (e.data.comentarios == undefined) ? '' : e.data.comentario
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionrrd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionrrd(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionrrd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },






        // MEDIO IMPUGNACION REVISION MULTA - A
        consultaMedioImpugnacionrma() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionrma(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionRMA = data;


            }
            );

        },

        agregarMedioImpugnacionrma(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse).format('YYYY-MM-DD'),

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionrma(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionrma(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse).format('YYYY-MM-DD'),
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionrma(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionrma(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionrma(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },






        // MEDIO IMPUGNACION REVISION MULTA - B
        consultaMedioImpugnacionrmb() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionrmb(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionRMB = data;


            }
            );

        },

        agregarMedioImpugnacionrmb(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnorevocacionmodificacion: (e.data.norevocacionmodificacion == undefined) ? '' : e.data.norevocacionmodificacion,
            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionrmb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionrmb(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnorevocacionmodificacion: (e.data.norevocacionmodificacion == undefined) ? '' : e.data.norevocacionmodificacion,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionrmb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionrmb(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionrmb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },






        // MEDIO IMPUGNACION REVISION MULTA - C
        consultaMedioImpugnacionrmc() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionrmc(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionRMC = data;


            }
            );

        },

        agregarMedioImpugnacionrmc(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnotificacion: (e.data.notificacion == undefined) ? '' : e.data.notificacion,
                pfechavencimiento: (e.data.fechavencimiento == undefined) ? '9999-01-01' : moment(e.data.fechavencimiento).format('YYYY-MM-DD'),
                pfechacuse: (e.data.fechacuse == undefined) ? '9999-01-01' : moment(e.data.fechacuse).format('YYYY-MM-DD'),
            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionrmc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionrmc(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnotificacion: (e.data.notificacion == undefined) ? '' : e.data.notificacion,
                pfechavencimiento: (e.data.fechavencimiento == '') ? '9999-01-01' : moment(e.data.fechavencimiento).format('YYYY-MM-DD'),
                pfechacuse: (e.data.fechacuse == '') ? '9999-01-01' : moment(e.data.fechacuse).format('YYYY-MM-DD'),
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionrmc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionrmc(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionrmc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },






        // MEDIO IMPUGNACION REVISION MULTA - D
        consultaMedioImpugnacionrmd() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionrmd(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionRMD = data;


            }
            );

        },

        agregarMedioImpugnacionrmd(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pmotivo: (e.data.motivo == undefined) ? '' : e.data.motivo,

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionrmd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionrmd(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pmotivo: (e.data.motivo == undefined) ? '' : e.data.motivo,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionrmd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionrmd(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionrmd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },










        // MEDIO IMPUGNACION REVISION MULTA - E
        consultaMedioImpugnacionrme() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionrme(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionRME = data;


            }
            );

        },


        agregarMedioImpugnacionrme(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfecharesolucion: (e.data.fecharesolucion == undefined) ? '9999-01-01' : moment(e.data.fecharesolucion).format('YYYY-MM-DD'),
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnotificacion: (e.data.notificacion == undefined) ? '' : e.data.notificacion,
                pcantidadmulta: (e.data.cantidadmulta == undefined) ? '' : e.data.cantidadmulta,
                pplazo: (e.data.plazo == undefined) ? '' : e.data.plazo,

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionrme(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionrme(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfecharesolucion: (e.data.fecharesolucion == '') ? '9999-01-01' : moment(e.data.fecharesolucion).format('YYYY-MM-DD'),
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnotificacion: (e.data.notificacion == undefined) ? '' : e.data.notificacion,
                pcantidadmulta: (e.data.cantidadmulta == undefined) ? '' : e.data.cantidadmulta,
                pplazo: (e.data.plazo == undefined) ? '' : e.data.plazo,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionrme(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionrme(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionrme(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },





        // MEDIO IMPUGNACION REVISION MULTA - F
        consultaMedioImpugnacionrmf() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacionrmf(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionRMF = data;


            }
            );

        },

        agregarMedioImpugnacionrmf(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse).format('YYYY-MM-DD'),

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacionrmf(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacionrmf(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse).format('YYYY-MM-DD'),
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacionrmf(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacionrmf(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacionrmf(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },








        // CONMUTACION MULTA - A
        consultaMedioImpugnacioncma() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacioncma(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionCMA = data;


            }
            );

        },

        agregarMedioImpugnacioncma(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse).format('YYYY-MM-DD'),
                pnombreproyecto: (e.data.nombreproyecto == undefined) ? '' : e.data.nombreproyecto,
                pnofianza: (e.data.nofianza == undefined) ? '' : e.data.nofianza,
                pmonto: (e.data.monto == undefined) ? '0' : e.data.monto,

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacioncma(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacioncma(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaacuse: (e.data.fechaacuse == '') ? '9999-01-01' : moment(e.data.fechaacuse).format('YYYY-MM-DD'),
                pnombreproyecto: (e.data.nombreproyecto == undefined) ? '' : e.data.nombreproyecto,
                pnofianza: (e.data.nofianza == undefined) ? '' : e.data.nofianza,
                pmonto: (e.data.monto == undefined) ? '0' : e.data.monto,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacioncma(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacioncma(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacioncma(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },






        // CONMUTACION MULTA - B
        consultaMedioImpugnacioncmb() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacioncmb(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionCMB = data;


            }
            );

        },

        agregarMedioImpugnacioncmb(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnonotificacion: (e.data.nonotificacion == undefined) ? '' : e.data.nonotificacion,
            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacioncmb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacioncmb(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnonotificacion: (e.data.nonotificacion == undefined) ? '' : e.data.nonotificacion,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacioncmb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacioncmb(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacioncmb(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },






        // CONMUTACION MULTA - C
        consultaMedioImpugnacioncmc() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacioncmc(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionCMC = data;


            }
            );

        },

        agregarMedioImpugnacioncmc(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnotificacion: (e.data.notificacion == undefined) ? '' : e.data.notificacion,
                pfechavencimiento: (e.data.fechavencimiento == undefined) ? '9999-01-01' : moment(e.data.fechavencimiento).format('YYYY-MM-DD'),
                pfechaacuse: (e.data.fechaacuse == undefined) ? '9999-01-01' : moment(e.data.fechaacuse).format('YYYY-MM-DD'),
            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacioncmc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacioncmc(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pnotificacion: (e.data.notificacion == undefined) ? '' : e.data.notificacion,
                pfechavencimiento: (e.data.fechavencimiento == '') ? '9999-01-01' : moment(e.data.fechavencimiento).format('YYYY-MM-DD'),
                pfechaacuse: (e.data.fechaacuse == '') ? '9999-01-01' : moment(e.data.fechaacuse).format('YYYY-MM-DD'),
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacioncmc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacioncmc(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacioncmc(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },






        // CONMUTACION MULTA - D
        consultaMedioImpugnacioncmd() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacioncmd(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionCMD = data;


            }
            );

        },

        agregarMedioImpugnacioncmd(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pmotivo: (e.data.motivo == undefined) ? '' : e.data.motivo,

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacioncmd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacioncmd(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                pmotivo: (e.data.motivo == undefined) ? '' : e.data.motivo,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacioncmd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacioncmd(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacioncmd(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },










        // CONMUTACION MULTA - E

        onMedioImpugnacionESeleccionadaDetalle({ selectedRowsData }) {
            const accion = selectedRowsData[0];

            this.conmutacion_e = accion.id;
             this.vis_medio_impugnaciondetalleCME_E = 'S';
            
        },

        consultaMedioImpugnacioncme() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacioncme(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionCME = data;


            }
            );

        },


        agregarMedioImpugnacioncme(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfecharesolucion: (e.data.fecharesolucion == undefined) ? '9999-01-01' : moment(e.data.fecharesolucion).format('YYYY-MM-DD'),
                pfechanotificacion: (e.data.fechanotificacion == undefined) ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                prequerimiento: (e.data.requerimiento == undefined) ? '' : e.data.requerimiento,

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacioncme(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacioncme(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfecharesolucion: (e.data.fecharesolucion == '') ? '9999-01-01' : moment(e.data.fecharesolucion).format('YYYY-MM-DD'),
                pfechanotificacion: (e.data.fechanotificacion == '') ? '9999-01-01' : moment(e.data.fechanotificacion).format('YYYY-MM-DD'),
                prequerimiento: (e.data.requerimiento == undefined) ? '' : e.data.requerimiento,
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacioncme(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacioncme(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacioncme(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },





        // CONMUTACION MULTA - E - E
        consultaMedioImpugnacioncme_e() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacioncme_e(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionCME_E = data;


            }
            );

        },


        agregarMedioImpugnacioncme_e(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnacion_cm_e_id: this.conmutacion_e,
                pdescripcion: (e.data.descripcion == undefined) ? '' : e.data.descripcion,
                pplazo: (e.data.plazo == undefined) ? '' : e.data.plazo,
                pfechapresentacion: (e.data.fechapresentacion == undefined) ? '9999-01-01' : moment(e.data.fechapresentacion).format('YYYY-MM-DD'),

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacioncme_e(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacioncme_e(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnacion_cm_e_id: this.conmutacion_e,
                pdescripcion: (e.data.descripcion == undefined) ? '' : e.data.descripcion,
                pplazo: (e.data.plazo == undefined) ? '' : e.data.plazo,
                pfechapresentacion: (e.data.fechapresentacion == '') ? '9999-01-01' : moment(e.data.fechapresentacion).format('YYYY-MM-DD'),
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacioncme_e(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacioncme_e(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacioncme_e(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },



        // CONMUTACION MULTA - F
        consultaMedioImpugnacioncmf() {

            let id = this.vidot;
            let myJSON = JSON.stringify({

                pordentrabajoid: id,

            });
            console.log(myJSON);
            this.ProcedimientoAdministrativo.getMedidaImpugnacioncmf(myJSON).then(async data => {
                this.dataSourceMedioImpugnacionCMF = data;


            }
            );

        },

        agregarMedioImpugnacioncmf(e) {
            console.log("LLEGA AQUI OPERACION");
            let id = this.vidot;
            let myJSON = JSON.stringify({
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaemision: (e.data.fechaemision == undefined) ? '9999-01-01' : moment(e.data.fechaemision).format('YYYY-MM-DD'),

            });

            this.ProcedimientoAdministrativo.createMedidaImpugnacioncmf(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Insertado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },

        editarMedioImpugnacioncmf(e) {

            let id = this.vidot;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: id,
                pproadm_medioimpugnaciondetalleid: this.vmedioimpugnaciondetalleid,
                pfechaemision: (e.data.fechaemision == '') ? '9999-01-01' : moment(e.data.fechaemision).format('YYYY-MM-DD'),
            });

            this.ProcedimientoAdministrativo.updateMedidaImpugnacioncmf(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Actualizado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },


        eliminarMedioImpugnacioncmf(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,


            });

            this.ProcedimientoAdministrativo.deleteMedidaImpugnacioncmf(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'Registro Eliminado',
                    }, 'success', 9000);

                    this.cargaDatos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }


            });


        },





        // SERVICIOS
        agregarServicios() {

            this.frm_servicio = null;
            this.frm_ecaid = null;
            this.frm_visitacampoid = null;
            this.frm_tipoproyectoid = null;
            this.dataSourceECA = null;
            this.frm_numero = null;
            this.frm_fechainiciovigencia = null;
            this.frm_fechafinvigencia = null;
            this.frm_seguimiento = null;
            this.frm_fechainicioplaneada = null;
            this.frm_clientecontacto = null;
            this.frm_fechafinplaneada = null;
            this.frm_observaciones = null;
            this.frm_responsable = null;
            this.loading = true;

            this.popupVisibleServicios = true;

        },
        guardarServicios() {


            let err = 0;
            let msgerror = '';

            let idusuario = JSON.parse(Cookies.get("ets_user")).id;


            if (this.frm_servicio == null) {
                err = 1;
                msgerror = 'Ingresar servicio';
            }
            if (err == 0) {
                if (this.frm_clientecontacto == null) {
                    err = 1;
                    msgerror = 'Ingresar contacto de cliente';
                }
            }
            if (err == 0) {
                if (this.frm_responsable == null) {
                    err = 1;
                    msgerror = 'Ingresar responsable de OT';
                }
            }
            if (err == 0) {
                if (this.frm_aplicaeca == 1 && this.frm_ecaid == null) {
                    err = 1;
                    msgerror = 'Seleccionar la ECA';
                }
            }

            if (err == 0) {
                if (this.frm_tipoproyectoid == null) {
                    err = 1;
                    msgerror = 'Ingresar tipo de proyecto';
                }
            }
            if (this.frm_visitacampoid == null || this.frm_visitacampoid == 'null') {

                this.frm_visitacampoid = 0;
            }

            let myJSONPOliza = JSON.stringify({
                pnumeroordentrabajo: "",
                pfechainicioplaneada: this.frm_fechainicioplaneada ? this.frm_fechainicioplaneada : null,
                pfechafinplaneada: this.frm_fechafinplaneada ? this.frm_fechafinplaneada : null,
                pobservacion: this.frm_observaciones,
                pusuarioregistroid: idusuario,
                ppoliza: this.vidot,
                pservicioid: this.frm_servicio,
                presponsabletecnicoid: this.frm_responsable,
                pecaid: this.frm_ecaid,
                ptipoproyectoid: this.frm_tipoproyectoid,
                pvisitacampoid: this.frm_visitacampoid,
                pclientecontactoid: this.frm_clientecontacto

            });
            console.log('DATOS ENVIADO A GUARDAR:' + myJSONPOliza);
            if (err == 0) {
                this.ProcedimientoAdministrativo.createOrdenTrabajoServicioProcedimiento(myJSONPOliza).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha guardado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisibleServicios = false;
                        this.cargaDatos();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }


                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }



        },
        actualizarServicios() {

            let err = 0;
            let msgerror = '';


            let idusuario = JSON.parse(Cookies.get("ets_user")).id;


            if (this.frm_ed_servicio == null) {
                err = 1;
                msgerror = 'Ingresar servicio';
            }

            if (err == 0) {
                if (this.frm_ed_clientecontacto == null) {
                    err = 1;
                    msgerror = 'Ingresar contacto de cliente';
                }
            }
            if (err == 0) {
                if (this.frm_ed_responsable == null) {
                    err = 1;
                    msgerror = 'Ingresar responsable de OT';
                }
            }


            if (err == 0) {
                if (this.frm_ed_tipoproyectoid == null) {
                    err = 1;
                    msgerror = 'Ingresar tipo de proyecto';
                }
            }
            if (this.frm_ed_visitacampoid == null || this.frm_ed_visitacampoid == 'null') {

                this.frm_ed_visitacampoid = 0;
            }

            let myJSON = JSON.stringify({
                pid: this.frm_ed_id,
                pservicioid: this.frm_ed_servicio,
                pfechainicioplaneada: this.frm_ed_fechainicioplaneada ? this.frm_ed_fechainicioplaneada : null,
                pfechafinplaneada: this.frm_ed_fechafinplaneada ? this.frm_ed_fechafinplaneada : null,
                pobservacion: this.frm_ed_observaciones,
                presponsabletecnicoid: this.frm_ed_responsable,
                ptipoproyectoid: this.frm_ed_tipoproyectoid,
                pvisitacampoid: this.frm_ed_visitacampoid,
                pusuarioregistroid: idusuario,
                pclientecontactoid: this.frm_ed_clientecontacto

            });


            if (err == 0) {


                this.ProcedimientoAdministrativo.updateOrdenTrabajoServicioProcedimiento(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);


                        this.popupVisibleServicios = false;
                        this.cargaDatos();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }


                });

            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }





        },
        eliminarServicios(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id.substring(2, 50),
            });



            this.ProcedimientoAdministrativo.deleteOrdenTrabajoServicioProcedimiento(myJSON).then(data => {

                if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha actualizado el registro';
                    this.isVisible = true;

                    notify({
                        message: this.message,
                    }, this.type, 5000);
                    this.popupVisible = false;
                    this.popupVisibleServicios = false;
                    this.cargaDatos();
                } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                    notify({
                        message: 'Ocurrio un error ' + this.message,
                    }, this.type, 4000);
                    this.popupVisibleServicios = false;
                    this.cargaDatos();


                }


            });

        },
        editServicios(e) {

            let vid = e.row.data.id;
            let vtipo = e.row.data.tiposervicioid;
            let vidparse = vid.substring(2, 50);
            // 1 = ECA
            if (vtipo == 1) {
                this.$router.push({ name: 'ordentrabajoresultadoconguia', params: { id: vidparse } });
            }
            else {
                this.$router.push({ name: 'ordentrabajoresultadoestudio', params: { id: vidparse } });
            }


        },
        editarOT(e) {
            console.log('DATOS EDICION:' + e);
            this.servicioService.getServicio().then(data => this.dataSourceServicio = data);

            this.frm_ed_id = e.row.data.idedicion;
            this.frm_ed_servicio = e.row.data.servicioid;

            this.frm_ed_tiposervicioid = e.row.data.tiposervicioid;
            this.frm_ed_responsable = e.row.data.responsabletecnicoid;
            this.frm_ed_tipoproyectoid = e.row.data.tipoproyectoid;
            this.frm_ed_visitacampoid = e.row.data.visitacampoid;
            this.frm_ed_fechainicioplaneada = e.row.data.fechainicioplaneada;
            this.frm_ed_fechafinplaneada = e.row.data.fechafinplaneada;
            this.frm_ed_observaciones = e.row.data.observacion;
            this.frm_ed_solicitud = e.row.data.solicitud;
            this.frm_ed_cotizacion = e.row.data.cotizacion;
            this.frm_ed_ot = e.row.data.numero;
            this.frm_ed_cliente = e.row.data.cliente;
            this.frm_ed_clientecontacto = e.row.data.clientecontactoid;

            if (
                this.frm_ed_tiposervicioid == '1' || this.frm_ed_tiposervicioid == 1 ||
                this.frm_ed_tiposervicioid == '3' || this.frm_ed_tiposervicioid == 3
            ) {
                this.v_ed_aplicacambioservicio = 'N';
            }
            else {
                this.v_ed_aplicacambioservicio = 'S';
            }
            this.popupVisibleServiciosEdicion = true;

        },


        onUploaded(e) {
            let id = this.vidot;
            let res = JSON.parse(e.request.responseText);
            let archivosubido = res.image_name;
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON = JSON.stringify({
                ptipodocumentoid: this.valtipodocumento,
                pnombredocumento: this.valnombredocumento,
                pruta: archivosubido,
                pordentrabajoid: id,
                pusuarioregistroid: idusuario
            });

            let err = 0;
            let msgerror = '';

            if (this.valtipodocumento == null || this.valtipodocumento == '' || this.valtipodocumento == 'undefined') {
                err = 1;
                msgerror = 'Ingresar tipo documento';
            }

            if (err == 0) {
                if (this.valnombredocumento == null || this.valnombredocumento == '' || this.valnombredocumento == 'undefined') {
                    err = 1;
                    msgerror = 'Ingresar nombre documento';
                }
            }

            if (err == 0) {
                this.ordentrabajodocumentoService.createOrdenTrabajoDocumento(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible = false;
                        this.valtipodocumento = null;
                        this.valnombredocumento = null;
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    //this.loadDataInfo();

                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }
        },
        onUploadError(e) {
            let xhttp = e.request;
            if (xhttp.status === 400) {
                e.message = e.error.responseText;
            }
            if (xhttp.readyState === 4 && xhttp.status === 0) {
                e.message = "Conexión rechazada";
            }
        },

        editRegisterCambioEtapa(e) {
            let id = this.vidot;
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON = JSON.stringify({
                pid: id,
                pusuario: idusuario,
                petapaid: e.data.etapaid,
            });

            this.ordentrabajoresultadoService.getOrdenTrabajoCierreEtapa(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'OT Etapa ha sido actualizada',
                    }, 'success', 9000);
                    //this.$router.push({ name: 'ordentrabajoresultadoconguia', params: { id: this.vidot } });
                    this.loadDataInfo();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    // document.location.reload();

                }


            });


        },
        editRegisterCambioEstatus(e) {

            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON = JSON.stringify({
                pid: this.vidot,
                pusuario: idusuario,
                pobservacion: e.data.observacion,
                pestatusid: e.data.estatusid,
            });
            console.log("UPDATE");
            console.log("UPDATE", e);
            console.log("UPDATE", myJSON);
            this.ordentrabajoresultadoService.getOrdenTrabajoCierre(myJSON).then(data => {

                if (data.err == 0) {

                    notify({
                        message: 'OT ha sido actualizada',
                    }, 'success', 9000);
                    this.loadDataInfo();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    // document.location.reload();

                }


            });

        },

        onAccionSeleccionada({ selectedRowsData }) {
            const accion = selectedRowsData[0];

            this.dataIdAccionSeleccionado = accion.id;

            let myJSONID = JSON.stringify({
                pid: accion.id,
            });

            this.ordentrabajoprocedimientomedidasService.getAccionMedida(myJSONID).then(data => {
                this.dataSourceMedidasMitigacion = data;
            });

        },
        agregarAccion(e) {
            let myJSONID = JSON.stringify({
                pordentrabajoid: this.vidot,
                pfactorambientalid: e.data.factorambientalid,
                pcomponentes: e.data.componentes,
                pacciones: e.data.acciones,
                petapas: e.data.etapas
            });

            this.ordentrabajoprocedimientomedidasService.createAccion(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Accion ha sido actualizada',
                    }, 'success', 9000);
                    this.loadDataAccion();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });
        },
        eliminarAccion(e) {

            let myJSONID = JSON.stringify({
                pid: e.data.id
            });

            this.ordentrabajoprocedimientomedidasService.deleteAccion(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'La acción ha sido eliminada',
                    }, 'success', 9000);
                    this.loadDataAccion();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });

        },
        editarAccion(e) {

            let myJSONID = JSON.stringify({
                pid: e.data.id,
                pordentrabajoid: this.vidot,
                pfactorambientalid: e.data.factorambientalid,
                pcomponentes: e.data.componentes,
                pacciones: e.data.acciones,
                petapas: e.data.etapas
            });


            this.ordentrabajoprocedimientomedidasService.updateAccion(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'La acción ha sido actualizada',
                    }, 'success', 9000);
                    this.loadDataAccion();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });
        },

        loadDataAccion() {

            let myJSON = JSON.stringify({
                pid: this.vidot
            });

            this.ordentrabajoprocedimientomedidasService.getAccion(myJSON).then(data => {
                this.dataSourceAccion = data;
            });

        },
        agregarMedida(e) {



            if (this.dataIdAccionSeleccionado === null || this.dataIdAccionSeleccionado == null) {

                notify({
                    message: 'Debe seleccionar una acción',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 9000);
                this.dataSourceMedidasMitigacion = null;
            }
            else {
                let myJSONID = JSON.stringify({
                    pordentrabajoestudioaccionid: this.dataIdAccionSeleccionado,
                    ptipomedidaid: e.data.tipomedidaid,
                    pmedida: e.data.medida
                });

                this.ordentrabajoprocedimientomedidasService.createMedida(myJSONID).then(data => {
                    if (data.err == 0) {
                        notify({
                            message: 'Medida ha sido insertada',
                        }, 'success', 9000);
                        this.loadDataMedida();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }

                });

            }

        },
        eliminarMedida(e) {
            let myJSONID = JSON.stringify({
                pid: e.data.id
            });

            this.ordentrabajoprocedimientomedidasService.deleteMedida(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'La medida ha sido eliminada',
                    }, 'success', 9000);
                    this.loadDataMedida();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });

        },
        editarMedida(e) {
            console.log("DDDDDDDDDDDDDDDDDDDDD", this.dataIdAccionSeleccionado);

            if (this.dataIdAccionSeleccionado === null || this.dataIdAccionSeleccionado == null) {

                notify({
                    message: 'Debe seleccionar una acción',
                    position: {
                        my: 'center top',
                        at: 'center top',
                    },
                }, 'error', 9000);
                this.dataSourceMedidasMitigacion = null;
            }
            else {
                let myJSONID = JSON.stringify({
                    pid: e.data.id,
                    ptipomedidaid: e.data.tipomedidaid,
                    pmedida: e.data.medida
                });

                this.ordentrabajoprocedimientomedidasService.updateMedida(myJSONID).then(data => {

                    if (data.err == 0) {
                        notify({
                            message: 'La medida ha sido actualizada',
                        }, 'success', 9000);
                        this.loadDataMedida();
                    } else {

                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                            position: {
                                my: 'center top',
                                at: 'center top',
                            },
                        }, 'error', 9000);

                    }

                });

            }

        },
        loadDataMedida() {

            let myJSONID = JSON.stringify({
                pid: this.dataIdAccionSeleccionado,
            });

            this.ordentrabajoprocedimientomedidasService.getAccionMedida(myJSONID).then(data => {
                this.dataSourceMedidasMitigacion = data;
            });
        },
        editarMedidaSeguimiento(e) {

            let myJSONID = JSON.stringify({
                pid: e.data.id,
                pfechalimite: e.data.fechalimite,
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion
            });

            this.ordentrabajoprocedimientomedidasService.updateMedidaSeguimiento(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'La medida seguimiento ha sido actualizada',
                    }, 'success', 9000);
                    this.loadDataMedidaSeguimiento();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });
        },
        loadDataMedidaSeguimiento() {
            let myJSON = JSON.stringify({
                pid: this.vidot
            });

            this.ordentrabajoprocedimientomedidasService.getMedidaSeguimiento(myJSON).then(data => {
                this.dataSourceMedidasSeguimiento = data;

            })
        },
        agregarTermino(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;
            let myJSONID = JSON.stringify({
                pordentrabajoid: this.vidot,
                pusuarioregistroid: idusuario,
                pnombre: e.data.nombre,
                pdescripcion: e.data.descripcion,
                pfechalimite: e.data.fechalimite,
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion,
                pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
                pevidencia_ruta: e.data.evidencia_ruta
            });

            this.ordentrabajoprocedimientoterminosService.createTermino(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Termino ha sido agregado',
                    }, 'success', 9000);
                    this.loadDataTerminos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }
            });


        },
        eliminarTermino(e) {

            let myJSONID = JSON.stringify({
                pid: e.data.id
            });

            this.ordentrabajoprocedimientoterminosService.deleteTermino(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'El termino ha sido eliminado',
                    }, 'success', 9000);
                    this.loadDataTerminos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });

        },
        editarTermino(e) {
            let myJSONID = JSON.stringify({
                pid: e.data.id,
                pnombre: e.data.nombre,
                pdescripcion: e.data.descripcion,
                pfechalimite: e.data.fechalimite,
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion,
                pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
                pevidencia_ruta: e.data.evidencia_ruta
            });

            this.ordentrabajoprocedimientoterminosService.updateTermino(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Termino ha sido actualizado',
                    }, 'success', 9000);
                    this.loadDataTerminos();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }
            });


        },
        loadDataTerminos() {
            let myJSON = JSON.stringify({
                pid: this.vidot
            });

            this.ordentrabajoprocedimientoterminosService.getTermino(myJSON).then(data => {
                this.dataSourceTermino = data;

            });
        },
        agregarCondicion(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;
            let myJSONID = JSON.stringify({
                pordentrabajoid: this.vidot,
                pusuarioregistroid: idusuario,
                pnombre: e.data.nombre,
                pdescripcion: e.data.descripcion,
                pfechalimite: e.data.fechalimite,
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion,
                pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
                pevidencia_ruta: e.data.evidencia_ruta
            });

            this.ordentrabajoprocedimientocondicionalesService.createCondicional(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Condicional ha sido agregada',
                    }, 'success', 9000);
                    this.loadDataCondicionales();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }
            });


        },
        eliminarCondicion(e) {

            let myJSONID = JSON.stringify({
                pid: e.data.id
            });

            this.ordentrabajoprocedimientocondicionalesService.deleteCondicional(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'La condicional ha sido eliminada',
                    }, 'success', 9000);
                    this.loadDataCondicionales();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });

        },

        onResourceAssigned(e) {
            //ASIGNED {taskId: 104, resourceId: 3}

            let myJSON = JSON.stringify({
                pordentrabajoid: this.vidot,
                pordentrabajoplanid: e.values.taskId,
                pclientecontactoid: e.values.resourceId,

            });


            if (e.values.taskID != 0) {
                this.ordentrabajoplanService.createOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible = false;
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    //this.loadDataInfo();

                });
            }
        }, onResourceUnassigned(e) {

            let myJSON = JSON.stringify({
                pid: e.key,


            });

            if (e.key != 0) {
                this.ordentrabajoplanService.deleteOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible = false;
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    //this.loadDataInfo();

                });
            }
        },
        onTaskDeleted(e) {
            let err = 0;
            if (e.key != 0) {


                let myJSON = JSON.stringify({
                    pid: e.key,
                    pot: this.vidot
                });





                if (e.key != 0) {

                    if (err == 0) {


                        this.ordentrabajoplanService.deleteOrdenTrabajoPlan(myJSON).then(data => {

                            if (data.err == 0) {
                                this.type = 'success';
                                this.message = 'Se ha eliminado el registro';
                                this.isVisible = true;

                                notify({
                                    message: this.message,
                                }, this.type, 5000);
                                this.popupVisible = false;
                                this.loadDataInfo();
                            } else {

                                this.type = 'error';
                                this.message = 'Ocurrio un error ' + data.msg;
                                this.isVisible = true;
                                notify({
                                    message: 'Ocurrio un error ' + data.msg,
                                }, this.type, 4000);


                            }


                        });




                    } else {
                        this.type = 'error';
                        this.message = 'error';
                        this.isVisible = true;
                        notify({
                            message: this.message,
                        }, this.type, 4000);

                    }
                    e.cancel = true;
                }
            }
        },

        onTaskInserted(e) {

            let err = 0;
            let msgerror = '';

            let vfechainicio = '';
            let vfechafin = '';
            let vprogreso = '0';
            let vdescripcion = '';
            let vpadre = '';



            // Si esta indefinido no hubo cambio
            // De lo contrario si hubo cambio

            if (e.values.parentId === undefined || e.values.parentId === '' || e.values.parentId === 'null' || e.values.parentId === null) {
                vpadre = "-1";

            }
            else {
                vpadre = e.values.parentId;
            }
            vdescripcion = e.values.title;
            vfechainicio = e.values.start;
            vfechafin = e.values.end;


            // let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON = JSON.stringify({

                pordentrabajoid: this.vidot,
                vpadre: vpadre,
                pdescripcion: vdescripcion,
                pfechainicio: vfechainicio,
                pfechafin: vfechafin,
                pprogreso: vprogreso
            });



            if (e.key != 0) {

                if (err == 0) {
                    this.ordentrabajoplanService.createOrdenTrabajoPlan(myJSON).then(data => {

                        if (data.err == 0) {
                            this.type = 'success';
                            this.message = 'Se ha insertado el registro';
                            this.isVisible = true;

                            notify({
                                message: this.message,
                            }, this.type, 5000);
                            this.popupVisible = false;
                            this.loadDataInfo();
                        } else {

                            this.type = 'error';
                            this.message = 'Ocurrio un error ' + data.msg;
                            this.isVisible = true;
                            notify({
                                message: 'Ocurrio un error ' + data.msg,
                            }, this.type, 4000);


                        }
                        //this.loadDataInfo();

                    });
                } else {
                    this.type = 'error';
                    this.message = msgerror;
                    this.isVisible = true;
                    notify({
                        message: this.message,
                    }, this.type, 4000);

                }
                e.cancel = true;
            }



        },
        onTaskUpdating(e) {



            let err = 0;
            let msgerror = '';

            let vfechainicio = '';
            let vfechafin = '';
            let vprogreso = '';
            let vdescripcion = '';



            // Si esta indefinido no hubo cambio
            // De lo contrario si hubo cambio

            if (e.newValues.title === undefined || e.newValues.title === '' || e.newValues.title === 'null' || e.newValues.title === null) {
                //vfechainicio = e.values.start.getFullYear() + '-' + (e.values.start.getMonth() + 1) + '-' + e.values.start.getDate();
                vdescripcion = e.values.title;

            }
            else {
                if (e.newValues.title == undefined || e.newValues.title == null || e.newValues.title == '') {
                    err = 1;
                    msgerror = 'Ingresar descripción';
                }
                else {
                    vdescripcion = e.newValues.title;

                }
            }


            if (e.newValues.start === undefined || e.newValues.start === '' || e.newValues.start === 'null' || e.newValues.start === null) {
                //vfechainicio = e.values.start.getFullYear() + '-' + (e.values.start.getMonth() + 1) + '-' + e.values.start.getDate();
                vfechainicio = e.values.start;

            }
            else {
                if (e.newValues.start == undefined || e.newValues.start == null || e.newValues.start == '') {
                    err = 1;
                    msgerror = 'Ingresar fecha inicio';
                }
                else {
                    vfechainicio = e.newValues.start;

                }
            }

            if (e.newValues.end === undefined || e.newValues.end === '' || e.newValues.end === 'null' || e.newValues.end === null) {
                //vfechafin =  e.values.end.getFullYear() + '-' + (e.values.end.getMonth() + 1) + '-' + e.values.end.getDate();
                vfechafin = e.values.end;
            }
            else {
                if (e.newValues.end == undefined || e.newValues.end == null || e.newValues.end == '') {
                    err = 1;
                    msgerror = 'Ingresar fecha fin';
                }
                else {
                    //vfechafin = e.newValues.end.getFullYear() + '-' + (e.newValues.end.getMonth() + 1) + '-' + e.newValues.end.getDate();
                    vfechafin = e.newValues.end;
                }
            }

            if (e.newValues.progress === undefined || e.newValues.progress === '' || e.newValues.progress === 'null' || e.newValues.progress === null) {


                vprogreso = e.values.progress;
            }
            else {
                if (e.newValues.progress === undefined || e.newValues.progress == null || e.newValues.progress == '') {
                    err = 1;
                    msgerror = 'Ingresar progreso';
                }
                else {

                    vprogreso = e.newValues.progress;
                }
            }



            // let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON = JSON.stringify({
                pid: e.key,
                pordentrabajoid: this.vidot,
                pdescripcion: vdescripcion,
                pfechainicio: vfechainicio,
                pfechafin: vfechafin,
                pprogreso: vprogreso
            });



            if (e.key != 0) {

                if (err == 0) {
                    this.ordentrabajoplanService.updateOrdenTrabajoPlan(myJSON).then(data => {

                        if (data.err == 0) {
                            this.type = 'success';
                            this.message = 'Se ha actualizado el registro';
                            this.isVisible = true;

                            notify({
                                message: this.message,
                            }, this.type, 5000);
                            this.popupVisible = false;
                            this.loadDataInfo();
                        } else {

                            this.type = 'error';
                            this.message = 'Ocurrio un error ' + data.msg;
                            this.isVisible = true;
                            notify({
                                message: 'Ocurrio un error ' + data.msg,
                            }, this.type, 4000);


                        }
                        //this.loadDataInfo();

                    });
                } else {
                    this.type = 'error';
                    this.message = msgerror;
                    this.isVisible = true;
                    notify({
                        message: this.message,
                    }, this.type, 4000);

                }
                e.cancel = true;
            }
        },



        editarCondicion(e) {
            let myJSONID = JSON.stringify({
                pid: e.data.id,
                pnombre: e.data.nombre,
                pdescripcion: e.data.descripcion,
                pfechalimite: e.data.fechalimite,
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion,
                pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
                pevidencia_ruta: e.data.evidencia_ruta
            });

            this.ordentrabajoprocedimientocondicionalesService.updateCondicional(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Condicional ha sido actualizada',
                    }, 'success', 9000);
                    this.loadDataCondicionales();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }
            });


        },
        loadDataCondicionales() {
            let myJSON = JSON.stringify({
                pid: this.vidot
            });

            this.ordentrabajoprocedimientocondicionalesService.getCondicional(myJSON).then(data => {
                this.dataSourceCondicion = data;

            });
        },
        agregarPrograma(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;
            let myJSONID = JSON.stringify({
                pordentrabajoid: this.vidot,
                pusuarioregistroid: idusuario,
                pnombre: e.data.nombre,
                pdescripcion: e.data.descripcion,
                pfechalimite: e.data.fechalimite,
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion,
                pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
                pevidencia_ruta: e.data.evidencia_ruta
            });

            this.ordentrabajoprocedimientoprogramasService.createPrograma(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Programa ha sido agregado',
                    }, 'success', 9000);
                    this.loadDataProgramas();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }
            });


        },
        eliminarPrograma(e) {

            let myJSONID = JSON.stringify({
                pid: e.data.id
            });

            this.ordentrabajoprocedimientoprogramasService.deletePrograma(myJSONID).then(data => {

                if (data.err == 0) {
                    notify({
                        message: 'El termino ha sido eliminado',
                    }, 'success', 9000);
                    this.loadDataProgramas();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }

            });

        },
        editarPrograma(e) {
            let myJSONID = JSON.stringify({
                pid: e.data.id,
                pnombre: e.data.nombre,
                pdescripcion: e.data.descripcion,
                pfechalimite: e.data.fechalimite,
                pcompletado: e.data.completado,
                pobservacion: e.data.observacion,
                pevidencia_nombredocumento: e.data.evidencia_nombredocumento,
                pevidencia_ruta: e.data.evidencia_ruta
            });

            this.ordentrabajoprocedimientoprogramasService.updatePrograma(myJSONID).then(data => {
                if (data.err == 0) {
                    notify({
                        message: 'Termino ha sido actualizado',
                    }, 'success', 9000);
                    this.loadDataProgramas();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);

                }
            });


        },
        loadDataProgramas() {
            let myJSON = JSON.stringify({
                pid: this.vidot
            });

            this.ordentrabajoprocedimientoprogramasService.getPrograma(myJSON).then(data => {
                this.dataSourcePrograma = data;

            });
        },
        loadDataInfo() {

            let myJSON2 = JSON.stringify({
                pid: this.vidot,
            });

            this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId(myJSON2).then(data => {
                this.customer = data
                this.pdfRazon = data.razonsocial;
                this.pdfFechaInicio = data.fechainicioplaneada;
            }

            );
            this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId2(myJSON2).then(data => {
                this.dataSourceCambioEstatus = data
            }

            );



            this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId2(myJSON2).then(data => {
                this.dataSourceEtapaCierre = data;
            }

            );


            this.tipodocumentoService.getTipoDocumento().then(data => {
                this.dataSourceDocumento = data;

            });



            //this.usuarioService.getUsuario().then(data => this.dataSourceUsuarios = data);
            let id = this.vidot;

            let myJSON = JSON.stringify({
                pid: id,

            });



            this.ordentrabajodocumentoService.getOrdenTrabajoDocumento(myJSON).then(data => {
                this.dataSourceDocumentacion = data;
            });




            /**
            this.ordentrabajoresultadoService.getOrdenTrabajoResultadoListadoDocumentacion(myJSON).then(data => {
                data.forEach(
                    element => {
  
                        if (element.entregado == 1) {
                            element.entregado = true;
                        } else {
                            element.entregado = false;
                        }
                        if (element.aplica == 1) {
                            element.aplica = true;
                        } else {
                            element.aplica = false;
                        }
  
                    });
                this.dataSourceListados = data;
  
            });
  
  
            this.ordentrabajoresultadoService.getOrdenTrabajoResultadoListadoInformacion(myJSON).then(data => {
                data.forEach(
                    element => {
  
                        if (element.entregado == 1) {
                            element.entregado = true;
                        } else {
                            element.entregado = false;
                        }
                        if (element.aplica == 1) {
                            element.aplica = true;
                        } else {
                            element.aplica = false;
                        }
  
                    });
                this.dataSourceEntrega = data;
  
            });
  
  
       
            this.ordentrabajoetapaService.getOrdenTrabajoEtapaConsulta(myJSON).then(data => {
                this.dataSourceEtapa = data;
            }
            );
            this.estatusService.getGrupoEstatusOrden(myJSON).then(data => this.dataSourceEstatus = data);
            **/

            ///SEGUIMIENTO
            this.loadDataAccion();
            // this.ordentrabajoprocedimientomedidasService.getAccion(myJSON).then(data => {
            //  this.dataSourceAccion = data;
            //});

            this.ordentrabajoprocedimientomedidasService.getTipoMedida().then(data => {
                this.dataSourceTipoMedida = data;
            });

            this.ordentrabajoprocedimientomedidasService.getFactorAmbiental().then(data => {
                this.dataSourceFactorAmbiental = data;
            });

            let myJSONID = JSON.stringify({
                pid: 0,
            });

            this.ordentrabajoprocedimientomedidasService.getAccionMedida(myJSONID).then(data => {
                this.dataSourceMedidasMitigacion = data;
            });

            this.ordentrabajoprocedimientomedidasService.getMedidaSeguimiento(myJSON).then(data => {
                this.dataSourceMedidasSeguimiento = data;

            })

            this.ordentrabajoprocedimientoterminosService.getTermino(myJSON).then(data => {
                this.dataSourceTermino = data;

            });

            this.ordentrabajoprocedimientocondicionalesService.getCondicional(myJSON).then(data => {
                this.dataSourceCondicion = data;

            });

            this.ordentrabajoprocedimientoprogramasService.getPrograma(myJSON).then(data => {
                this.dataSourcePrograma = data;

            });
            //////////////////////////////////

            // GANTT
            /**
            this.ordentrabajoplanService.getOrdenTrabajoPlanTareas(myJSON).then(data => {
                this.tasks = data;
  
            }
            );
  
            this.ordentrabajoplanService.getOrdenTrabajoPlanDependencias(myJSON).then(data => {
                this.dependencies = data
            }
            );
  
  
            this.ordentrabajoplanService.getOrdenTrabajoPlanContactoCliente(myJSON).then(data => { this.resources = data });
  
  
            this.ordentrabajoplanService.getOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
                this.resourceAssignments = data
            }
            );
            **/
            // FIN GANTT

        },
        passwordComparison() {
            return this.customer.Password;
        },
        checkComparison() {
            return true;
        },
        asyncValidation(params) {
            return sendRequest(params.value);
        },
        handleSubmit(e) {




            let myJSON = JSON.stringify({
                pid: this.customer.ID,
                pnombre: this.customer.Nombre,
                ppaterno: this.customer.Paterno,
                pmaterno: this.customer.Materno,
                pusuario: this.customer.Usuario,
                pcontrasena: this.customer.Password,
                pcorreo: this.customer.Email,
            });


            this.usuarioService.updateUsuarioPerfil(myJSON).then(data => {

                if (data.err == 0) {
                    Cookies.set("ets_user", "null");
                    let myJSON = JSON.stringify({
                        id: this.customer.ID,
                        nombre: this.customer.Nombre + ' ' + this.customer.Paterno + ' ' + this.customer.Materno,
                        correo: this.customer.Email,
                    });
                    Cookies.set("ets_user", myJSON);
                    auth.logIn(this.customer.Nombre + ' ' + this.customer.Paterno + ' ' + this.customer.Materno);
                    notify({
                        message: 'Perfil ha sido actualizado',
                    }, 'success', 9000);
                    document.location.reload();
                } else {

                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                    document.location.reload();

                }


            });

            e.preventDefault();
        },
        downloadfile(e) {
            let name_file = e.row.data.ruta;


            axios({
                url: process.env.VUE_APP_APIURL + '/api/archivodownload?name_file=' + name_file,
                method: 'GET',
                responseType: 'blob',

            }).then((res) => {
                var url = window.URL.createObjectURL((res.data));
                var namepdf = name_file;
                //var cliente = "";
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', namepdf);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });



        },
        editProcedureListadoInformacion(e) {

            let ventregado = e.data.entregado == true ? 1 : 0;
            let vaplica = e.data.aplica == true ? 1 : 0;
            let idusuario = JSON.parse(Cookies.get("ets_user")).id;//1;
            let myJSON;
            if (e.data.aplica) {
                myJSON = JSON.stringify({
                    pid: e.data.id,
                    pentregado: null,
                    pfechaentrega: '9999-01-01',
                    pfechacompromisoentrega: '9999-01-01',
                    pusuarioregistroid: idusuario,
                    pobservaciones: '',
                    precibio: '',
                    paplica: vaplica,
                });
            } else {
                myJSON = JSON.stringify({
                    pid: e.data.id,
                    pentregado: ventregado,
                    pfechaentrega: (e.data.fechaentrega == '') ? '9999-01-01' : e.data.fechaentrega,
                    pfechacompromisoentrega: (e.data.fechacompromisoentrega == '') ? '9999-01-01' : e.data.fechacompromisoentrega,
                    pusuarioregistroid: idusuario,
                    pobservaciones: (e.data.observaciones == '') ? '' : e.data.observaciones,
                    precibio: (e.data.recibio == '') ? '' : e.data.recibio,
                    paplica: vaplica,
                });
            }

            this.esCampoRequerido = true;

            this.ordentrabajoresultadoService.updateOrdenTrabajoListadoInformacion(myJSON).then(data => {

                if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha actualizado el registro';
                    this.isVisible = true;

                    notify({
                        message: this.message,
                    }, this.type, 9000);
                    //this.loadDataInfo();
                } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                }
                this.loadDataInfo();

            });

        },
        editProcedureListadoDocumentacion(e) {

            let ventregado = e.data.entregado == true ? 1 : 0;
            let vaplica = e.data.aplica == true ? 1 : 0;
            let myJSON;
            if (e.data.aplica) {
                myJSON = JSON.stringify({
                    pid: e.data.id,
                    pentregado: null,
                    pfechaentrega: null,
                    paplica: vaplica,

                });
            } else {
                myJSON = JSON.stringify({
                    pid: e.data.id,
                    pentregado: ventregado,
                    pfechaentrega: e.data.fechaentrega,
                    paplica: vaplica,

                });
            }

            this.esCampoRequerido2 = true;

            this.ordentrabajoresultadoService.updateOrdenTrabajoListadoDocumentacion(myJSON).then(data => {

                if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha actualizado el registro';
                    this.isVisible = true;

                    notify({
                        message: this.message,
                    }, this.type, 9000);
                    this.loadDataInfo();
                } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                    notify({
                        message: 'Ocurrio un error ' + data.msg,
                        position: {
                            my: 'center top',
                            at: 'center top',
                        },
                    }, 'error', 9000);
                }
                this.loadDataInfo();

            });

        },
        editorPreparingListados(e) {
            if (e.dataField === 'aplica' && e.parentType === 'dataRow') {

                e.editorOptions.onValueChanged = (innerEvent) => {
                    //this.$forceUpdate();
                    // innerEvent.value contiene el nuevo valor del checkbox
                    let valor = innerEvent.value;
                    this.esCampoRequerido = !valor;
                    e.setValue(valor);

                };
            }
        },
        editorPreparingDocumentos(e) {
            if (e.dataField === 'aplica' && e.parentType === 'dataRow') {

                e.editorOptions.onValueChanged = (innerEvent) => {
                    //this.$forceUpdate();
                    // innerEvent.value contiene el nuevo valor del checkbox
                    let valor = innerEvent.value;
                    this.esCampoRequerido2 = !valor;
                    e.setValue(valor);

                };
            }
        },
        onInitNewRow(e) {
            e.data.entregado = true;
        },
        onInitNewRow_medida(e) {
            console.log(e);
        },
    },
};
