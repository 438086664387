
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxButton,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxGrouping,
    DxGroupPanel,


} from 'devextreme-vue/data-grid';
import 'devextreme-vue/text-area';

import { DxToast } from 'devextreme-vue/toast';
import OrdenTrabajoResultadonService from '../services/ordentrabajoresultado-service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import Cookies from "js-cookie";
window.Cookies = Cookies;



export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxButton,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,


        DxGrouping,
        DxGroupPanel
    },
    data() {
        return {
            procedimientos: null,
            dataSourceUsuarios: null,
            dataSourceRoles: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',

            suppliersData: createStore({
                key: 'SupplierID',
            }),
        };
    },
    created() {
        this.ordentrabajoejecucionService = new OrdenTrabajoResultadonService();



    },
    mounted() {
        this.ordentrabajoejecucionService.getOrdenTrabajoEjecucion().then(data => this.dataSourceUsuarios = data);
    },
    methods: {
        editSkill(e) {


            let vid = e.row.data.id;
            let vtipo = e.row.data.tiposervicioid;
            let vidparse = vid.substring(2, 50);
            console.log("TIPO...", vid);

            switch (vtipo) {
                case 1: //ECA
                    this.$router.push({ name: 'ordentrabajoresultadoconguia', params: { id: vidparse } });
                    break;
                case 2: //ESTUDIO
                    this.$router.push({ name: 'ordentrabajoresultadoestudio', params: { id: vidparse } });
                    break;
                case 3: //POLIZA
                    this.$router.push({ name: 'ordentrabajoresultadopoliza', params: { id: vidparse } });
                    break;
                case 4: // PROCEDIMIENTO
                    this.$router.push({ name: 'ordentrabajoresultadoprocedimiento', params: { id: vidparse } });
                    break;
                default: // DEFAULT
                    this.$router.push({ name: 'ordentrabajoresultadoestudio', params: { id: vidparse } });
                    break;
            }



        },
    }

};
